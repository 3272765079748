export const ColorList = [
  {
    label: "Acero",
    hex: "#8b8589",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Agua",
    hex: "#66ada4",
    link: "https://es.wikipedia.org/wiki/Azul_verde#Coloraciones_azul_verdes",
  },
  {
    label: "Aguacate o Palta",
    hex: "#568203",
    link: "https://es.wikipedia.org/wiki/Verde_amarillo#Otros_ejemplos_verde-amarillos",
  },
  {
    label: "Aguamarina",
    hex: "#9fd5d1",
    link: "https://es.wikipedia.org/wiki/Aguamarina_(color)",
  },
  {
    label: "Albaricoque",
    hex: "#fab57f",
    link: "https://es.wikipedia.org/wiki/Mel%C3%B3n_(color)#Ejemplos_y_coloraciones_similares",
  },
  {
    label: "Almagre",
    hex: "#d4442f",
    link: "https://es.wikipedia.org/wiki/Almagre",
  },
  {
    label: "Almendra",
    hex: "#c19a6b",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Otros_ejemplos_y_tipos_de_marrón",
  },
  {
    label: "Aluminio",
    hex: "#b9b8b5",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Aluminio Blanco",
    hex: "#a5a5a5",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Aluminio Gris",
    hex: "#8f8f8f",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amaranto",
    hex: "#e52b50",
    link: "https://es.wikipedia.org/wiki/Amaranto_(color)",
  },
  {
    label: "Amarillo",
    hex: "#ffe900",
    link: "https://es.wikipedia.org/wiki/Amarillo",
  },
  {
    label: "Amarillo Arena",
    hex: "#c6a664",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Azafrán",
    hex: "#f5d033",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Azufre",
    hex: "#edff21",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Brillante",
    hex: "#ffff00",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Cadmio",
    hex: "#fff600",
    link: "https://es.wikipedia.org/wiki/Amarillo_cadmio",
  },
  {
    label: "Amarillo Chino",
    hex: "#fada5e",
    link: "https://es.wikipedia.org/wiki/Amarillo#Ejemplos_de_amarillos_y_colores_próximos",
  },
  {
    label: "Amarillo Colza",
    hex: "#f3da0b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Curry",
    hex: "#9d9101",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Dalia",
    hex: "#f3a505",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Fluorescente",
    hex: "#ccff00",
    link: "https://es.wikipedia.org/wiki/Lima_(color)#Limas_o_verde-amarillos_espectrales",
  },
  {
    label: "Amarillo Hansa",
    hex: "#ffcd00",
    link: "https://es.wikipedia.org/wiki/Amarillo_Hansa",
  },
  {
    label: "Amarillo Indio",
    hex: "#f39430",
    link: "https://es.wikipedia.org/wiki/Amarillo_indio",
  },
  {
    label: "Amarillo Limón",
    hex: "#c7b446",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Maiz",
    hex: "#e4a010",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Melón",
    hex: "#f4a900",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Miel",
    hex: "#a98307",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Monoazo",
    hex: "#ffeb00",
    link: "https://es.wikipedia.org/wiki/Amarillo_monoazo",
  },
  {
    label: "Amarillo Naranja",
    hex: "#ed760e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Narciso",
    hex: "#dc9d00",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Nápoles",
    hex: "#eccd6a",
    link: "https://es.wikipedia.org/wiki/Amarillo_N%C3%A1poles",
  },
  {
    label: "Amarillo Ocre",
    hex: "#aea04b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Oliva",
    hex: "#999950",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Oro",
    hex: "#cda434",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Pastel",
    hex: "#efa94a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Patito",
    hex: "#fad201",
    link: "https://es.wikipedia.org/wiki/Amarillo_tr%C3%A1fico",
  },
  {
    label: "Amarillo Retama",
    hex: "#d6ae01",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Selectivo",
    hex: "#ffba00",
    link: "https://es.wikipedia.org/wiki/Amarillo_selectivo",
  },
  {
    label: "Amarillo Señales",
    hex: "#e5be01",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Sol",
    hex: "#f39f18",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Tráfico",
    hex: "#fbd302",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amarillo Verdoso",
    hex: "#ded700",
    link: "https://es.wikipedia.org/wiki/Verde_amarillo#Comparación_con_colores_próximos",
  },
  {
    label: "Amarillo de Cobalto",
    hex: "#ffbf08",
    link: "https://es.wikipedia.org/wiki/Amarillo_de_cobalto",
  },
  {
    label: "Amarillo de Cromo",
    hex: "#ffcc0f",
    link: "https://es.wikipedia.org/wiki/Amarillo_de_cromo",
  },
  {
    label: "Amarillo de Zinc",
    hex: "#f8f32b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Amatista",
    hex: "#9966cc",
    link: "https://es.wikipedia.org/wiki/Amatista_(color)",
  },
  {
    label: "Anaranjado",
    hex: "#ef7f1a",
    link: "https://es.wikipedia.org/wiki/Anaranjado",
  },
  {
    label: "Ante",
    hex: "#bf8f4e",
    link: "https://es.wikipedia.org/wiki/Ante_(color)",
  },
  {
    label: "Antracita",
    hex: "#383e42",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Arena",
    hex: "#ece2c6",
    link: "https://es.wikipedia.org/wiki/Arena_(color)",
  },
  {
    label: "Asfalto",
    hex: "#555555",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Aureolina",
    hex: "#d7b615",
    link: "https://es.wikipedia.org/wiki/Aureolina",
  },
  {
    label: "Avellana",
    hex: "#b48969",
    link: "https://es.wikipedia.org/wiki/Marron_(color)#Otros_ejemplos_y_tipos_de_marrón",
  },
  {
    label: "Azabache",
    hex: "#343434",
    link: "https://es.wikipedia.org/wiki/Negro_(color)#Ejemplos_de_coloraciones_negras",
  },
  {
    label: "Azafrán",
    hex: "#f4c430",
    link: "https://es.wikipedia.org/wiki/Amarillo_naranja#Coloraciones_amarillo-naranjas",
  },
  {
    label: "Azarcón",
    hex: "#ff4000",
    link: "https://es.wikipedia.org/wiki/Azarc%C3%B3n",
  },
  {
    label: "Azul",
    hex: "#0070b8",
    link: "https://es.wikipedia.org/wiki/Azul",
  },
  {
    label: "Azul Acero",
    hex: "#231a24",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Aciano",
    hex: "#8f97cb",
    link: "https://es.wikipedia.org/wiki/Azul_aciano",
  },
  {
    label: "Azul Agua",
    hex: "#256d7b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Alicia",
    hex: "#91a3b0",
    link: "https://es.wikipedia.org/wiki/Azul_Alicia",
  },
  {
    label: "Azul Azur",
    hex: "#025669",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Bebé o Azul Celeste",
    hex: "#89cff0",
    link: "https://es.wikipedia.org/wiki/Celeste_(color)#Variedades_de_celeste",
  },
  {
    label: "Azul Brillante",
    hex: "#3e5f8a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Capri",
    hex: "#1b5583",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Celeste",
    hex: "#2271b3",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Cerúleo",
    hex: "#0067a5",
    link: "https://es.wikipedia.org/wiki/Cer%C3%BAleo#Variedad",
  },
  {
    label: "Azul Cian",
    hex: "#00a0e3",
    link: "https://es.wikipedia.org/wiki/Cian_(color)#Cian_sustractivo_(color_primario)",
  },
  {
    label: "Azul Cobalto",
    hex: "#1e213d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Columbia",
    hex: "#78bbe4",
    link: "https://es.wikipedia.org/wiki/Azul_Columbia",
  },
  {
    label: "Azul Egipcio",
    hex: "#1034a6",
    link: "https://es.wikipedia.org/wiki/Azul#Tipos_de_azules",
  },
  {
    label: "Azul Eléctrico",
    hex: "#1630be",
    link: "https://es.wikipedia.org/wiki/Azul#Tipos_de_azules",
  },
  {
    label: "Azul Eton",
    hex: "#96c8a2",
    link: "https://es.wikipedia.org/wiki/Azul_Eton",
  },
  {
    label: "Azul Ftalo",
    hex: "#000f89",
    link: "https://es.wikipedia.org/wiki/Ftalocianina",
  },
  {
    label: "Azul Genciana",
    hex: "#0e294b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Glauco",
    hex: "#007e8b",
    link: "https://es.wikipedia.org/wiki/Turquesa_(color)#Turquesa_pictórico",
  },
  {
    label: "Azul Grisáceo",
    hex: "#26252d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Horizonte",
    hex: "#7ca2b8",
    link: "https://es.wikipedia.org/wiki/Azul_horizonte",
  },
  {
    label: "Azul Klein",
    hex: "#002fa7",
    link: "https://es.wikipedia.org/wiki/Azul_Klein",
  },
  {
    label: "Azul Lavanda",
    hex: "#8c92ac",
    link: "https://es.wikipedia.org/wiki/B%C3%ADgaro_(color)#Coloraciones_similares",
  },
  {
    label: "Azul Lejanía",
    hex: "#49678d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Lino o Azulino",
    hex: "#6c79b8",
    link: "https://es.wikipedia.org/wiki/B%C3%ADgaro_(color)#Coloraciones_similares",
  },
  {
    label: "Azul Luminoso",
    hex: "#3b83bd",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Majorelle",
    hex: "#5564eb",
    link: "https://es.wikipedia.org/wiki/Azul_Majorelle",
  },
  {
    label: "Azul Marino",
    hex: "#00304e",
    link: "https://es.wikipedia.org/wiki/Azul_marino",
  },
  {
    label: "Azul Maya",
    hex: "#73c2fb",
    link: "https://es.wikipedia.org/wiki/Azul_maya",
  },
  {
    label: "Azul Medianoche",
    hex: "#2a2a46",
    link: "https://es.wikipedia.org/wiki/Azul_marino#Variedad_de_azules_oscuros",
  },
  {
    label: "Azul Monastral",
    hex: "#01108a",
    link: "https://es.wikipedia.org/wiki/Ftalocianina",
  },
  {
    label: "Azul Negruzco",
    hex: "#18171c",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Noche",
    hex: "#252850",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Noche Perlado",
    hex: "#102c54",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Oceano",
    hex: "#1d334a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Olombino",
    hex: "#606e8c",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Pastel",
    hex: "#5d9b9b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Persa",
    hex: "#1c39bb",
    link: "https://es.wikipedia.org/wiki/Azul#Tipos_de_azules",
  },
  {
    label: "Azul Porcelana",
    hex: "#436b95",
    link: "https://es.wikipedia.org/wiki/Azul#Tipos_de_azules",
  },
  {
    label: "Azul Púrpura",
    hex: "#4c2882",
    link: "https://es.wikipedia.org/wiki/Azul_p%C3%BArpura",
  },
  {
    label: "Azul Real",
    hex: "#00416a",
    link: "https://es.wikipedia.org/wiki/Azul#Tipos_de_azules",
  },
  {
    label: "Azul Señales",
    hex: "#1e2460",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Tiffany",
    hex: "#81d8d0",
    link: "https://es.wikipedia.org/wiki/Azul_Tiffany",
  },
  {
    label: "Azul Tráfico",
    hex: "#063971",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Turquesa",
    hex: "#3f888f",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Ultramar",
    hex: "#20214f",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Verde",
    hex: "#009b94",
    link: "https://es.wikipedia.org/wiki/Azul_verde",
  },
  {
    label: "Azul Verdoso",
    hex: "#1f3438",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Violeta",
    hex: "#354d73",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul Zafiro",
    hex: "#1d1e33",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Azul de Prusia",
    hex: "#033e72",
    link: "https://es.wikipedia.org/wiki/Azul_de_Prusia",
  },
  {
    label: "Azur",
    hex: "#0080ff",
    link: "https://es.wikipedia.org/wiki/Azur#El_color_azur_en_la_actualidad",
  },
  {
    label: "Añil",
    hex: "#1c4c96",
    link: "https://es.wikipedia.org/wiki/A%C3%B1il",
  },
  {
    label: "Banana",
    hex: "#fbec5d",
    link: "https://es.wikipedia.org/wiki/Ma%C3%ADz_(color)",
  },
  {
    label: "Barbecho",
    hex: "#c29b6c",
    link: "https://es.wikipedia.org/wiki/Barbecho_(color)",
  },
  {
    label: "Begonia",
    hex: "#e66761",
    link: "https://es.wikipedia.org/wiki/Coral_(color)#Otras_muestras_coralinas",
  },
  {
    label: "Beige",
    hex: "#c2b078",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Beige Agrisado",
    hex: "#9e9764",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Beige Pardo",
    hex: "#8a6642",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Beige Perlado",
    hex: "#6a5d4d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Beige Verdoso",
    hex: "#bebd7f",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Beige o Beis",
    hex: "#e8c39e",
    link: "https://es.wikipedia.org/wiki/Beige",
  },
  {
    label: "Berenjena",
    hex: "#291e29",
    link: "https://es.wikipedia.org/wiki/Negro_(color)#Otros_ejemplos_negruzcos",
  },
  {
    label: "Bermejo",
    hex: "#b3342c",
    link: "https://es.wikipedia.org/wiki/Bermejo_(color)",
  },
  {
    label: "Bermellón",
    hex: "#e62e00",
    link: "https://es.wikipedia.org/wiki/Bermell%C3%B3n",
  },
  {
    label: "Bistre",
    hex: "#877d60",
    link: "https://es.wikipedia.org/wiki/Bistre",
  },
  {
    label: "Bistre Marrón",
    hex: "#967117",
    link: "https://es.wikipedia.org/wiki/Bistre#El_bistre_como_color",
  },
  {
    label: "Blanco",
    hex: "#ffffff",
    link: "https://es.wikipedia.org/wiki/Blanco",
  },
  {
    label: "Blanco Crema",
    hex: "#fdf4e3",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Blanco Grisáceo",
    hex: "#e7ebda",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Blanco Humo",
    hex: "#b4bcc0",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Blanco Navajo",
    hex: "#ebe1c9",
    link: "https://es.wikipedia.org/wiki/Blanco_navajo",
  },
  {
    label: "Blanco Papiro",
    hex: "#d7d7d7",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Blanco Perla",
    hex: "#eae6ca",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Blanco Puro",
    hex: "#fefefe",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Blanco Señales",
    hex: "#f4f4f4",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Blanco Tráfico",
    hex: "#f6f6f6",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Blanco de Zinc/cinc",
    hex: "#fafbfd",
    link: "https://es.wikipedia.org/wiki/Blanco_de_cinc",
  },
  {
    label: "Borgoña",
    hex: "#900020",
    link: "https://es.wikipedia.org/wiki/Borgo%C3%B1a_(color)",
  },
  {
    label: "Borravino",
    hex: "#56070c",
    link: "https://es.wikipedia.org/wiki/Borravino",
  },
  {
    label: "Bronce",
    hex: "#cd7f32",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Otros_ejemplos_y_tipos_de_marrón",
  },
  {
    label: "Bronceado",
    hex: "#d2b48c",
    link: "https://es.wikipedia.org/wiki/Bronceado_(color)",
  },
  {
    label: "Burdeos",
    hex: "#641c34",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Burdeos Oscuro",
    hex: "#651d35",
    link: "https://github.com/meodai/noms-de-couleur/#authors-@lmuntaner",
  },
  {
    label: "Burdeos o Bordó",
    hex: "#96305a",
    link: "https://es.wikipedia.org/wiki/Burdeos_(color)",
  },
  {
    label: "Bígaro",
    hex: "#ccccff",
    link: "https://es.wikipedia.org/wiki/B%C3%ADgaro_(color)",
  },
  {
    label: "Café",
    hex: "#591f0b",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Café",
  },
  {
    label: "Café con Leche Cósmico",
    hex: "#fff8e7",
    link: "https://es.wikipedia.org/wiki/Caf%C3%A9_con_leche_c%C3%B3smico",
  },
  {
    label: "Calabaza",
    hex: "#eeb283",
    link: "https://es.wikipedia.org/wiki/Calabaza_(color)",
  },
  {
    label: "Calipso",
    hex: "#31aac1",
    link: "https://es.wikipedia.org/wiki/Celeste_(color)#Variedades_de_celeste",
  },
  {
    label: "Camello",
    hex: "#c39c6d",
    link: "https://es.wikipedia.org/wiki/Camello_(color)",
  },
  {
    label: "Canela",
    hex: "#bd8a3e",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Canela_o_canelo",
  },
  {
    label: "Caoba",
    hex: "#4c2f27",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Caqui",
    hex: "#dfc38a",
    link: "https://es.wikipedia.org/wiki/Caqui_(color)",
  },
  {
    label: "Caramelo",
    hex: "#ae6938",
    link: "https://es.wikipedia.org/wiki/Color_Caramelo_(colorante)",
  },
  {
    label: "Carbón",
    hex: "#2b2a29",
    link: "https://es.wikipedia.org/wiki/Carb%C3%B3n_(color)",
  },
  {
    label: "Cardenal",
    hex: "#c41e3a",
    link: "https://es.wikipedia.org/wiki/Cardenal_(color)",
  },
  {
    label: "Cardenillo",
    hex: "#6dc1ae",
    link: "https://es.wikipedia.org/wiki/Cardenillo",
  },
  {
    label: "Cardo",
    hex: "#d8bfd8",
    link: "https://es.wikipedia.org/wiki/Cardo_(color)",
  },
  {
    label: "Carmesí",
    hex: "#ae0e36",
    link: "https://es.wikipedia.org/wiki/Carmes%C3%AD",
  },
  {
    label: "Carmín",
    hex: "#d31c4f",
    link: "https://es.wikipedia.org/wiki/Carm%C3%ADn",
  },
  {
    label: "Carmín Alizarina",
    hex: "#860919",
    link: "https://es.wikipedia.org/wiki/Carm%C3%ADn_de_alizarina",
  },
  {
    label: "Carne o Carnación",
    hex: "#fcd0b4",
    link: "https://es.wikipedia.org/wiki/Carne_(color)",
  },
  {
    label: "Cartujo",
    hex: "#c4c574",
    link: "https://es.wikipedia.org/wiki/Chartreuse_(color)#Variedad",
  },
  {
    label: "Castaña o Castaño",
    hex: "#773525",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Castaño_y_castaña",
  },
  {
    label: "Castaño",
    hex: "#633a34",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Celeste",
    hex: "#0cb7f2",
    link: "https://es.wikipedia.org/wiki/Celeste_(color)",
  },
  {
    label: "Ceniza",
    hex: "#cdcdcd",
    link: "https://es.wikipedia.org/wiki/Gris_ceniza",
  },
  {
    label: "Cerceta",
    hex: "#008080",
    link: "https://es.wikipedia.org/wiki/Azul_verde#Coloraciones_azul_verdes",
  },
  {
    label: "Cereza",
    hex: "#de3163",
    link: "https://es.wikipedia.org/wiki/Rojo_p%C3%BArpura#Ejemplos_de_rojo_púrpura",
  },
  {
    label: "Cerúleo",
    hex: "#0098d5",
    link: "https://es.wikipedia.org/wiki/Cer%C3%BAleo",
  },
  {
    label: "Cetrino",
    hex: "#e4d00a",
    link: "https://es.wikipedia.org/wiki/Amarillo#Ejemplos_de_amarillos_y_colores_próximos",
  },
  {
    label: "Champán",
    hex: "#f3e5ab",
    link: "https://es.wikipedia.org/wiki/Ma%C3%ADz_(color)#Colores_similares",
  },
  {
    label: "Chartreuse",
    hex: "#beb72d",
    link: "https://es.wikipedia.org/wiki/Chartreuse_(color)",
  },
  {
    label: "Chocolate",
    hex: "#45322e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Chocolate Magico",
    hex: "#4a2c2a",
    link: "https://github.com/meodai/noms-de-couleur/#authors-@meodai",
  },
  {
    label: "Cian",
    hex: "#00ffff",
    link: "https://es.wikipedia.org/wiki/Cian",
  },
  {
    label: "Cielo",
    hex: "#00ccff",
    link: "https://es.wikipedia.org/w/index.php?title=Cielo_(color)&action=edit&redlink=1",
  },
  {
    label: "Ciruela",
    hex: "#78184a",
    link: "https://es.wikipedia.org/wiki/P%C3%BArpura#Muestras",
  },
  {
    label: "Cobre Perlado",
    hex: "#763c28",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Cobre o Cobrizo",
    hex: "#cb6d51",
    link: "https://es.wikipedia.org/wiki/Cobre_(color)",
  },
  {
    label: "Concha",
    hex: "#f4c2c2",
    link: "https://es.wikipedia.org/wiki/Concha_(color)",
  },
  {
    label: "Conchevino",
    hex: "#673147",
    link: "https://es.wikipedia.org/wiki/Vino_(color)#Ejemplos_de_colores_vino_o_avinados",
  },
  {
    label: "Coral",
    hex: "#e4717a",
    link: "https://es.wikipedia.org/wiki/Coral_(color)",
  },
  {
    label: "Cordobán",
    hex: "#924549",
    link: "https://es.wikipedia.org/wiki/Cordob%C3%A1n_(color)",
  },
  {
    label: "Corinto",
    hex: "#bb5d5e",
    link: "https://es.wikipedia.org/wiki/Vino_(color)#Ejemplos_de_colores_vino_o_avinados",
  },
  {
    label: "Crema",
    hex: "#fff0c9",
    link: "https://es.wikipedia.org/wiki/Crema_(color)",
  },
  {
    label: "Cuarzo",
    hex: "#d9d9f3",
    link: "https://es.wikipedia.org/wiki/Blanco#Variedades_del_blanco",
  },
  {
    label: "Damasco",
    hex: "#fbb680",
    link: "https://es.wikipedia.org/wiki/Mel%C3%B3n_(color)#Ejemplos_y_coloraciones_similares",
  },
  {
    label: "Dark",
    hex: "#ffe5b4",
    link: "https://es.wikipedia.org/w/index.php?title=Dark_(color)&action=edit&redlink=1",
  },
  {
    label: "Dorado",
    hex: "#d4af37",
    link: "https://es.wikipedia.org/wiki/Oro_(color)",
  },
  {
    label: "Durazno",
    hex: "#fee6b5",
    link: "https://es.wikipedia.org/wiki/Durazno_(color)",
  },
  {
    label: "Elefante",
    hex: "#9b8f79",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Encaje Antiguo",
    hex: "#fdf5e6",
    link: "https://es.wikipedia.org/wiki/Encaje_antiguo",
  },
  {
    label: "Escarlata",
    hex: "#fd2d1c",
    link: "https://es.wikipedia.org/wiki/Escarlata_(color)",
  },
  {
    label: "Esmeralda",
    hex: "#009975",
    link: "https://es.wikipedia.org/wiki/Esmeralda_(color)",
  },
  {
    label: "Espinaca",
    hex: "#4a5d23",
    link: "https://es.wikipedia.org/wiki/Verde#Verdes_amarillentos",
  },
  {
    label: "Fandango",
    hex: "#b53389",
    link: "https://es.wikipedia.org/wiki/Rojo_p%C3%BArpura#Ejemplos_de_rojo_púrpura",
  },
  {
    label: "Feldgrau",
    hex: "#59646a",
    link: "https://es.wikipedia.org/wiki/Feldgrau",
  },
  {
    label: "Frambuesa",
    hex: "#e62c58",
    link: "https://es.wikipedia.org/wiki/Frambuesa_(color)",
  },
  {
    label: "Fucsia",
    hex: "#ff0080",
    link: "https://es.wikipedia.org/wiki/Fucsia",
  },
  {
    label: "Gamuza",
    hex: "#e8bd85",
    link: "https://es.wikipedia.org/wiki/Gamuza_(color)",
  },
  {
    label: "Gencian Perlado",
    hex: "#2a6478",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Geranio",
    hex: "#e52d33",
    link: "https://es.wikipedia.org/wiki/Rojo#Ejemplos_de_coloraciones_rojas",
  },
  {
    label: "Glauco",
    hex: "#008094",
    link: "https://es.wikipedia.org/wiki/Azul_verde#Coloraciones_azul_verdes",
  },
  {
    label: "Grafito",
    hex: "#1c1c1c",
    link: "https://es.wikipedia.org/wiki/Negro_(color)#Ejemplos_de_coloraciones_negras",
  },
  {
    label: "Grana",
    hex: "#c72744",
    link: "https://es.wikipedia.org/wiki/Carm%C3%ADn#Como_color",
  },
  {
    label: "Granate",
    hex: "#800000",
    link: "https://es.wikipedia.org/wiki/Granate_(color)",
  },
  {
    label: "Gris",
    hex: "#808080",
    link: "https://es.wikipedia.org/wiki/Gris",
  },
  {
    label: "Gris Acorazado",
    hex: "#848482",
    link: "https://es.wikipedia.org/wiki/Gris_acorazado",
  },
  {
    label: "Gris Amarillento",
    hex: "#8f8b66",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Antracita",
    hex: "#293133",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Ardilla",
    hex: "#78858b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Azulado",
    hex: "#474b4e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Basalto",
    hex: "#4e5754",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Beige",
    hex: "#6d6552",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Cadete",
    hex: "#81878b",
    link: "https://es.wikipedia.org/wiki/Gris_cadete",
  },
  {
    label: "Gris Caqui",
    hex: "#6a5f31",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Cemento",
    hex: "#7d8471",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Ceniza",
    hex: "#cecece",
    link: "https://es.wikipedia.org/wiki/Gris_ceniza",
  },
  {
    label: "Gris Claro Perlado",
    hex: "#9c9c9c",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Cuarzo",
    hex: "#6c6960",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Frío",
    hex: "#496063",
    link: "https://es.wikipedia.org/wiki/Gris_fr%C3%ADo",
  },
  {
    label: "Gris Grafita",
    hex: "#474a51",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Granito",
    hex: "#2f353b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Guijarro",
    hex: "#b8b799",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Hierro",
    hex: "#434b4d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Hormigón",
    hex: "#686c5e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Lona",
    hex: "#4c514a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Luminoso",
    hex: "#d8d8d8",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Musgo",
    hex: "#6c7059",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Musgo Perlado",
    hex: "#898176",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Negruzco",
    hex: "#23282b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Oliva",
    hex: "#7e7b52",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Oscuro Perlado",
    hex: "#828282",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Parduzco",
    hex: "#464531",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Piedra",
    hex: "#8b8c7a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Pizarra",
    hex: "#434750",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Plata",
    hex: "#8a9597",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Platino",
    hex: "#7f7679",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Polvo",
    hex: "#7d7f7d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Ratón",
    hex: "#646b63",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Seda",
    hex: "#cac4b0",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Señales",
    hex: "#969992",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Sombra",
    hex: "#332f2c",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Tele 1",
    hex: "#909090",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Tele 2",
    hex: "#82898f",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Tele 4",
    hex: "#d0d0d0",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Tráfico B",
    hex: "#4e5452",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Tráfico a",
    hex: "#8d948d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Ventana",
    hex: "#9da1aa",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris Verdoso",
    hex: "#4d5645",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gris de Campaña",
    hex: "#5a656b",
    link: "https://es.wikipedia.org/wiki/Feldgrau",
  },
  {
    label: "Gris de Davy",
    hex: "#565656",
    link: "https://es.wikipedia.org/wiki/Gris_de_Davy",
  },
  {
    label: "Gris de Payne",
    hex: "#536878",
    link: "https://es.wikipedia.org/wiki/Gris_de_Payne",
  },
  {
    label: "Gris Ágata",
    hex: "#b5b8b1",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Gualda o Gualdo",
    hex: "#fabd00",
    link: "https://es.wikipedia.org/wiki/Oro_(color)#Coloraciones_doradas",
  },
  {
    label: "Guinda",
    hex: "#952f57",
    link: "https://es.wikipedia.org/wiki/Guinda_(color)",
  },
  {
    label: "Gules",
    hex: "#f00909",
    link: "https://es.wikipedia.org/wiki/Gules",
  },
  {
    label: "Guta o Gutagamba",
    hex: "#e49b0f",
    link: "https://es.wikipedia.org/wiki/Gutagamba",
  },
  {
    label: "Habano",
    hex: "#844e34",
    link: "https://github.com/meodai/noms-de-couleur/#authors-@meodai",
  },
  {
    label: "Herrumbre",
    hex: "#a2522b",
    link: "https://es.wikipedia.org/wiki/Herrumbre_(color)",
  },
  {
    label: "Hielo",
    hex: "#a2c8d4",
    link: "https://github.com/meodai/noms-de-couleur/#authors-@meodai",
  },
  {
    label: "Hojaldre",
    hex: "#fde7b6",
    link: "https://github.com/meodai/noms-de-couleur/#authors-@lmuntaner",
  },
  {
    label: "Hueso",
    hex: "#e3dac9",
    link: "https://es.wikipedia.org/wiki/Blanco#Variedades_del_blanco",
  },
  {
    label: "Humo",
    hex: "#92a4b1",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Hígado",
    hex: "#891e35",
    link: "https://es.wikipedia.org/wiki/H%C3%ADgado_(color)",
  },
  {
    label: "Jade",
    hex: "#00a86b",
    link: "https://es.wikipedia.org/wiki/Verde#Verdes_azulados",
  },
  {
    label: "Kalua",
    hex: "#b78e5b",
    link: "https://es.wikipedia.org/wiki/Marron_(color)#Otros_ejemplos_y_tipos_de_marrón",
  },
  {
    label: "Lacre",
    hex: "#a73330",
    link: "https://es.wikipedia.org/wiki/Rojo#Ejemplos_de_coloraciones_rojas",
  },
  {
    label: "Ladrillo",
    hex: "#b22222",
    link: "https://github.com/meodai/noms-de-couleur/#authors-@meodai",
  },
  {
    label: "Ladrillo Oscuro",
    hex: "#443229",
    link: "https://github.com/meodai/noms-de-couleur/#authors-@meodai",
  },
  {
    label: "Lapislázuli",
    hex: "#4273b8",
    link: "https://es.wikipedia.org/wiki/Lapisl%C3%A1zuli",
  },
  {
    label: "Latón",
    hex: "#ab9144",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Otros_ejemplos_y_tipos_de_marrón",
  },
  {
    label: "Lava",
    hex: "#483c32",
    link: "https://es.wikipedia.org/wiki/Lava_(color)#Colores_«lava»_negruzcos:_lava_solidificada",
  },
  {
    label: "Lava Fundida",
    hex: "#b82928",
    link: "https://es.wikipedia.org/wiki/Lava_(color)",
  },
  {
    label: "Lavanda",
    hex: "#b57edc",
    link: "https://es.wikipedia.org/wiki/Lavanda_(color)",
  },
  {
    label: "León o Leonado",
    hex: "#c49d6e",
    link: "https://es.wikipedia.org/wiki/Le%C3%B3n_(color)",
  },
  {
    label: "Lila",
    hex: "#c8a2c8",
    link: "https://es.wikipedia.org/wiki/Lila_(color)",
  },
  {
    label: "Lila Azulado",
    hex: "#6c4675",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Lima",
    hex: "#bfff00",
    link: "https://es.wikipedia.org/wiki/Lima_(color)",
  },
  {
    label: "Lima-limón",
    hex: "#e3ff00",
    link: "https://es.wikipedia.org/wiki/Lima_(color)#Lima_o_verde-amarillos_espectrales",
  },
  {
    label: "Limón",
    hex: "#d9e542",
    link: "https://es.wikipedia.org/wiki/Lim%C3%B3n_(color)",
  },
  {
    label: "Lino",
    hex: "#d7d0b7",
    link: "https://es.wikipedia.org/wiki/Lino_(color)",
  },
  {
    label: "Lirio",
    hex: "#7f69a5",
    link: "https://es.wikipedia.org/wiki/Lirio_(color)",
  },
  {
    label: "Llama",
    hex: "#f98f1d",
    link: "https://es.wikipedia.org/wiki/Llama_(color)",
  },
  {
    label: "Lombarda",
    hex: "#852c84",
    link: "https://es.wikipedia.org/wiki/Morado#Coloraciones_similares",
  },
  {
    label: "Lúcuma",
    hex: "#ffba14",
    link: "https://es.wikipedia.org/wiki/Amarillo_naranja#Coloraciones_amarillo-naranjas",
  },
  {
    label: "Magenta",
    hex: "#ff00ff",
    link: "https://es.wikipedia.org/wiki/Magenta",
  },
  {
    label: "Magenta Tele",
    hex: "#cf3476",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Malaquita",
    hex: "#0bda51",
    link: "https://es.wikipedia.org/wiki/Malaquita#Color_malaquita",
  },
  {
    label: "Malva",
    hex: "#e0b0ff",
    link: "https://es.wikipedia.org/wiki/Malva_(color)",
  },
  {
    label: "Mamey",
    hex: "#f06030",
    link: "https://es.wikipedia.org/wiki/Rojo_naranja#Muestras_rojo_anaranjadas",
  },
  {
    label: "Mandarina",
    hex: "#ed872d",
    link: "https://es.wikipedia.org/wiki/Naranja_(color)#Ejemplos_de_colores_anaranjados",
  },
  {
    label: "Marfil",
    hex: "#e1cc4f",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Marfil Claro",
    hex: "#e6d690",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Marmol",
    hex: "#993300",
    link: "https://es.wikipedia.org/wiki/Marmol",
  },
  {
    label: "Marron Castaño",
    hex: "#643b35",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Marrón Cuero",
    hex: "#97572b",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Otros_ejemplos_y_tipos_de_marrón",
  },
  {
    label: "Marrón Dorado",
    hex: "#996515",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Otros_ejemplos_y_tipos_de_marrón",
  },
  {
    label: "Marrón Señales",
    hex: "#6c3b2a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Marrón Tierra",
    hex: "#4e3b31",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Maíz",
    hex: "#fced5e",
    link: "https://es.wikipedia.org/wiki/Ma%C3%ADz_(color)",
  },
  {
    label: "Melocotón",
    hex: "#fce8b7",
    link: "https://es.wikipedia.org/wiki/Durazno_(color)",
  },
  {
    label: "Melón",
    hex: "#fbca7f",
    link: "https://es.wikipedia.org/wiki/Mel%C3%B3n_(color)",
  },
  {
    label: "Menta",
    hex: "#bff7dc",
    link: "https://es.wikipedia.org/wiki/Menta_(color)",
  },
  {
    label: "Miel",
    hex: "#d29340",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Otros_ejemplos_y_tipos_de_marrón",
  },
  {
    label: "Molvada",
    hex: "#e2afed",
    link: "https://es.wikipedia.org/wiki/Naranja_(color)#Ejemplos_de_colores_anaranjados",
  },
  {
    label: "Morado",
    hex: "#a349a4",
    link: "https://es.wikipedia.org/wiki/Morado",
  },
  {
    label: "Morado Perlado",
    hex: "#6c6874",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Mostaza",
    hex: "#ffdb58",
    link: "https://es.wikipedia.org/wiki/Amarillo_naranja#Coloraciones_amarillo-naranjas",
  },
  {
    label: "Musgo",
    hex: "#51794b",
    link: "https://es.wikipedia.org/wiki/Verde#Verdes_agrisados",
  },
  {
    label: "Naranja",
    hex: "#f0801b",
    link: "https://es.wikipedia.org/wiki/Naranja_(color)",
  },
  {
    label: "Naranja Brillante",
    hex: "#ff2301",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja Cadmio",
    hex: "#ee882e",
    link: "https://es.wikipedia.org/wiki/Naranja_(color)#Ejemplos_de_colores_anaranjados",
  },
  {
    label: "Naranja Caqui",
    hex: "#f38d3c",
    link: "https://es.wikipedia.org/wiki/Caqui_(color)#Naranja_caqui",
  },
  {
    label: "Naranja Claro Brillante",
    hex: "#ffa420",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja Intenso",
    hex: "#ec7c26",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja Perlado",
    hex: "#c35831",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja Persa",
    hex: "#d99058",
    link: "https://es.wikipedia.org/wiki/Naranja_persa",
  },
  {
    label: "Naranja Puro",
    hex: "#f44611",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja Pálido",
    hex: "#ff7514",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja Rojizo",
    hex: "#fe6448",
    link: "https://es.wikipedia.org/wiki/Naranja_(color)",
  },
  {
    label: "Naranja Salmón",
    hex: "#e55137",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja Sanguineo",
    hex: "#cb2821",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja Señales",
    hex: "#d84b20",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja Tráfico",
    hex: "#f54021",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Naranja de Portland",
    hex: "#ff5a36",
    link: "https://es.wikipedia.org/wiki/Naranja_de_portland",
  },
  {
    label: "Negro",
    hex: "#000000",
    link: "https://es.wikipedia.org/wiki/Negro_(color)",
  },
  {
    label: "Negro Bujía",
    hex: "#2a3223",
    link: "https://es.wikipedia.org/wiki/Negro_de_humo#Negro_bujía",
  },
  {
    label: "Negro Grafito",
    hex: "#1d1d1d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Negro Intenso",
    hex: "#0a0a0a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Negro Señales",
    hex: "#282828",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Negro Tráfico",
    hex: "#1e1e1e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Negro de Humo",
    hex: "#382212",
    link: "https://es.wikipedia.org/wiki/Negro_de_humo",
  },
  {
    label: "Nogal",
    hex: "#5d432c",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Otros_ejemplos_y_tipos_de_marrón",
  },
  {
    label: "Níquel",
    hex: "#8f817f",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Ocaso",
    hex: "#fad6a5",
    link: "https://es.wikipedia.org/wiki/Ocaso_(color)",
  },
  {
    label: "Ocre",
    hex: "#b9935a",
    link: "https://es.wikipedia.org/wiki/Ocre#El_color_ocre",
  },
  {
    label: "Ocre Amarillo",
    hex: "#d9ad2a",
    link: "https://es.wikipedia.org/wiki/Ocre#Ocres_de_uso_artístico",
  },
  {
    label: "Ocre Dorado",
    hex: "#c97e28",
    link: "https://es.wikipedia.org/wiki/Ocre#Ocre_dorado",
  },
  {
    label: "Ocre Pardo",
    hex: "#5f3f3e",
    link: "https://es.wikipedia.org/wiki/Ocre#Ocre_pardo",
  },
  {
    label: "Ocre Rojo",
    hex: "#823a3f",
    link: "https://es.wikipedia.org/wiki/Ocre#Ocre_rojo_tostado",
  },
  {
    label: "Oliva",
    hex: "#808000",
    link: "https://es.wikipedia.org/wiki/Oliva_(color)#Colores_web",
  },
  {
    label: "Oliva Grisáceo",
    hex: "#3e3b32",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Oliva Negruzco",
    hex: "#3b3c36",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Oliva Parduzco",
    hex: "#25221b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Oro",
    hex: "#ffd700",
    link: "https://es.wikipedia.org/wiki/Oro_(color)",
  },
  {
    label: "Oro Perlado",
    hex: "#705335",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Oro Viejo",
    hex: "#cfb53b",
    link: "https://es.wikipedia.org/wiki/Oro_(color)#Coloraciones_doradas",
  },
  {
    label: "Orquídea",
    hex: "#da70d6",
    link: "https://es.wikipedia.org/wiki/Malva_(color)#Colores_web",
  },
  {
    label: "Palo Rosa",
    hex: "#dd969c",
    link: "https://es.wikipedia.org/wiki/Rosa_(color)#Ejemplos_del_color_rosado",
  },
  {
    label: "Palta o Aguacate",
    hex: "#578304",
    link: "https://es.wikipedia.org/wiki/Verde_amarillo#Otros_ejemplos_verde-amarillos",
  },
  {
    label: "Pardo",
    hex: "#ac8147",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Pardo",
  },
  {
    label: "Pardo Anaranjado",
    hex: "#a65e2e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Arcilla",
    hex: "#734222",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Beige",
    hex: "#79553d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Cobre",
    hex: "#8e402a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Corzo",
    hex: "#59351f",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Grisáceo",
    hex: "#403a3a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Negruzco",
    hex: "#212121",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Nuez",
    hex: "#5b3a29",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Ocre",
    hex: "#955f20",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Oliva",
    hex: "#6f4f28",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Pálido",
    hex: "#755c48",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Rojo",
    hex: "#592321",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Pardo Verdoso",
    hex: "#826c34",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Perla",
    hex: "#f0ead6",
    link: "https://es.wikipedia.org/wiki/Blanco#Variedades_del_blanco",
  },
  {
    label: "Perú",
    hex: "#cd853f",
    link: "https://es.wikipedia.org/wiki/Marr%C3%B3n#Colores_web",
  },
  {
    label: "Piel",
    hex: "#ffd4b2",
    link: "https://es.wikipedia.org/wiki/Piel_(color)",
  },
  {
    label: "Pistacho",
    hex: "#93c572",
    link: "https://es.wikipedia.org/wiki/Verde#Verdes_agrisados",
  },
  {
    label: "Pizarra",
    hex: "#5d6770",
    link: "https://es.wikipedia.org/wiki/Pizarra_(color)",
  },
  {
    label: "Plata o Plateado",
    hex: "#c0c0c0",
    link: "https://es.wikipedia.org/wiki/Plata_(color)",
  },
  {
    label: "Platino",
    hex: "#e5e4e2",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Plomo",
    hex: "#575757",
    link: "https://es.wikipedia.org/wiki/Plomo_(color)",
  },
  {
    label: "Porcelana",
    hex: "#446c96",
    link: "https://es.wikipedia.org/wiki/Azul#Tipos_de_azules",
  },
  {
    label: "Porráceo",
    hex: "#245338",
    link: "https://es.wikipedia.org/wiki/Verde#Verdes_agrisados",
  },
  {
    label: "Puerro",
    hex: "#cbc65d",
    link: "https://es.wikipedia.org/wiki/Verde_amarillo#Otros_ejemplos_verde-amarillos",
  },
  {
    label: "Punzó",
    hex: "#e62e11",
    link: "https://es.wikipedia.org/wiki/Punz%C3%B3",
  },
  {
    label: "Purpúreo",
    hex: "#9a4eae",
    link: "https://es.wikipedia.org/wiki/P%C3%BArpura#Purpúreo_o_púrpura_en_biología",
  },
  {
    label: "Púrpura",
    hex: "#6a0dad",
    link: "https://es.wikipedia.org/wiki/P%C3%BArpura",
  },
  {
    label: "Púrpura Eléctrico",
    hex: "#cc00ff",
    link: "https://es.wikipedia.org/wiki/P%C3%BArpura#Púrpura_espectral",
  },
  {
    label: "Púrpura Francés",
    hex: "#9e0e40",
    link: "https://es.wikipedia.org/wiki/P%C3%BArpura#Púrpura_en_francés",
  },
  {
    label: "Púrpura de Tiro",
    hex: "#7b236d",
    link: "https://es.wikipedia.org/wiki/P%C3%BArpura_de_Tiro",
  },
  {
    label: "Púrpurá Tráfico",
    hex: "#a03472",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Quercitrón",
    hex: "#e5b03d",
    link: "https://es.wikipedia.org/wiki/Quercitr%C3%B3n",
  },
  {
    label: "Quermes",
    hex: "#c42d45",
    link: "https://es.wikipedia.org/wiki/Kermes_(pigmento)",
  },
  {
    label: "Rojo",
    hex: "#e60026",
    link: "https://es.wikipedia.org/wiki/Rojo",
  },
  {
    label: "Rojo Anaranjado",
    hex: "#c93c20",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Beige",
    hex: "#c1876b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Brillante",
    hex: "#f80000",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Carmin",
    hex: "#a2231d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Claro Anaranjado",
    hex: "#f75e25",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Claro Brillante",
    hex: "#fe0000",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Coral",
    hex: "#b32821",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Falun",
    hex: "#a54332",
    link: "https://es.wikipedia.org/wiki/Rojo_de_Falun",
  },
  {
    label: "Rojo Ferrari",
    hex: "#cc0000",
    link: "https://es.wikipedia.org/wiki/Rojo_Ferrari",
  },
  {
    label: "Rojo Frambuesa",
    hex: "#c51d34",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Fresa",
    hex: "#d53032",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Fucsia",
    hex: "#ff005a",
    link: "https://es.wikipedia.org/wiki/Fucsia",
  },
  {
    label: "Rojo Indio",
    hex: "#882d17",
    link: "https://es.wikipedia.org/wiki/Rojo_indio",
  },
  {
    label: "Rojo Lila",
    hex: "#6d3f5b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Magenta",
    hex: "#e5097f",
    link: "https://es.wikipedia.org/wiki/Magenta#Magenta_sustractivo_(color_primario)",
  },
  {
    label: "Rojo Negruzco",
    hex: "#412227",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Neón",
    hex: "#fe575f",
    link: "https://es.wikipedia.org/wiki/Rojo#Ejemplos_de_coloraciones_rojas",
  },
  {
    label: "Rojo Oriente",
    hex: "#b32428",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Pardo",
    hex: "#781f19",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Periodo",
    hex: "#9c0002",
    link: "https://es.wikipedia.org/wiki/Rojo#Ejemplos_de_coloraciones_rojas",
  },
  {
    label: "Rojo Persa",
    hex: "#cb1d11",
    link: "https://es.wikipedia.org/wiki/Rojo_persa",
  },
  {
    label: "Rojo Puro",
    hex: "#cb3234",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Purpura",
    hex: "#75151e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Púrpura",
    hex: "#b21154",
    link: "https://es.wikipedia.org/wiki/Rojo_p%C3%BArpura",
  },
  {
    label: "Rojo Rubí",
    hex: "#9b111e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Rubí Perlado",
    hex: "#721422",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Salmón",
    hex: "#d95030",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Sangre",
    hex: "#9c0001",
    link: "https://es.wikipedia.org/wiki/Rojo#Ejemplos_de_coloraciones_rojas",
  },
  {
    label: "Rojo Señales",
    hex: "#a52019",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Tomate",
    hex: "#a12312",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Toscano",
    hex: "#79443b",
    link: "https://es.wikipedia.org/wiki/Rojo_toscano",
  },
  {
    label: "Rojo Tráfico",
    hex: "#cc0605",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Upsdell",
    hex: "#ae2029",
    link: "https://es.wikipedia.org/wiki/Rojo_Upsdell",
  },
  {
    label: "Rojo Veneciano",
    hex: "#6e433c",
    link: "https://es.wikipedia.org/wiki/Rojo_veneciano",
  },
  {
    label: "Rojo Viejo",
    hex: "#d36e70",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Vino",
    hex: "#5e2129",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Violeta",
    hex: "#922b3e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Vivo",
    hex: "#af2b1e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rojo Óxido",
    hex: "#642424",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rosa",
    hex: "#e63244",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rosa Claro",
    hex: "#ea899a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rosa Coral",
    hex: "#f88379",
    link: "https://es.wikipedia.org/wiki/Coral_(color)#Rosa_coral",
  },
  {
    label: "Rosa Lavanda",
    hex: "#e8ccd7",
    link: "https://es.wikipedia.org/wiki/Rosa_(color)#Ejemplos_del_color_rosado",
  },
  {
    label: "Rosa Malva",
    hex: "#d597ae",
    link: "https://es.wikipedia.org/wiki/Rosa_(color)#Ejemplos_del_color_rosado",
  },
  {
    label: "Rosa Mexicano",
    hex: "#f50087",
    link: "https://es.wikipedia.org/wiki/Rosa_mexicano",
  },
  {
    label: "Rosa Mountbatten",
    hex: "#997a8d",
    link: "https://es.wikipedia.org/wiki/Rosa_Mountbatten",
  },
  {
    label: "Rosa Naranja",
    hex: "#ff9966",
    link: "https://es.wikipedia.org/wiki/Rosa_naranja",
  },
  {
    label: "Rosa Perlado",
    hex: "#b44c43",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Rosa Shocking",
    hex: "#fc0fc0",
    link: "https://es.wikipedia.org/wiki/Rosa_shocking",
  },
  {
    label: "Rosa o Rosado",
    hex: "#ffc0cb",
    link: "https://es.wikipedia.org/wiki/Rosa_(color)",
  },
  {
    label: "Rosado Claro",
    hex: "#fec1cc",
    link: "https://es.wikipedia.org/wiki/Rosa_(color)#Ejemplos_del_color_rosado",
  },
  {
    label: "Rubí",
    hex: "#e0115f",
    link: "https://es.wikipedia.org/wiki/Rojo#Ejemplos_de_coloraciones_rojas",
  },
  {
    label: "Rufo",
    hex: "#cc6e52",
    link: "https://es.wikipedia.org/wiki/Rufo_(color)",
  },
  {
    label: "Salmón",
    hex: "#f28773",
    link: "https://es.wikipedia.org/wiki/Salm%C3%B3n_(color)",
  },
  {
    label: "Salvia",
    hex: "#93a996",
    link: "https://github.com/meodai/noms-de-couleur/#authors-@meodai",
  },
  {
    label: "Salvia Azulado",
    hex: "#245b60",
    link: "https://github.com/meodai/noms-de-couleur/#authors-@meodai",
  },
  {
    label: "Sangría",
    hex: "#92000a",
    link: "https://es.wikipedia.org/wiki/Vino_(color)#Ejemplos_de_colores_vino_o_avinados",
  },
  {
    label: "Secuoya",
    hex: "#8a5754",
    link: "https://es.wikipedia.org/wiki/Secuoya_(color)",
  },
  {
    label: "Sepia",
    hex: "#382c1e",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Siena",
    hex: "#c58a3e",
    link: "https://es.wikipedia.org/wiki/Siena_(color)",
  },
  {
    label: "Siena Tostada",
    hex: "#892e18",
    link: "https://es.wikipedia.org/wiki/Siena_(color)#Siena_tostada",
  },
  {
    label: "Sinople",
    hex: "#008c00",
    link: "https://es.wikipedia.org/wiki/Esmalte_(her%C3%A1ldica)#Códigos_de_color_RGB_y_CYMK",
  },
  {
    label: "Teja",
    hex: "#b94841",
    link: "https://es.wikipedia.org/wiki/Rojo#Ejemplos_de_coloraciones_rojas",
  },
  {
    label: "Tomate",
    hex: "#ff6347",
    link: "https://es.wikipedia.org/wiki/Tomate_(color)",
  },
  {
    label: "Torcaza o Topo",
    hex: "#87794e",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Trigo",
    hex: "#f5deb3",
    link: "https://es.wikipedia.org/wiki/Trigo_(color)",
  },
  {
    label: "Turquesa",
    hex: "#40e0d0",
    link: "https://es.wikipedia.org/wiki/Turquesa_(color)",
  },
  {
    label: "Turquesa Menta",
    hex: "#497e76",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Turquesa Pastel",
    hex: "#7fb5b5",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Turquí",
    hex: "#122562",
    link: "https://es.wikipedia.org/wiki/Turqu%C3%AD",
  },
  {
    label: "Té Verde",
    hex: "#cffdaf",
    link: "https://es.wikipedia.org/wiki/Verde_amarillo#Otros_ejemplos_verde-amarillos",
  },
  {
    label: "Ultramar",
    hex: "#4374b9",
    link: "https://es.wikipedia.org/wiki/Azul_ultramar",
  },
  {
    label: "Uva",
    hex: "#2f2140",
    link: "https://es.wikipedia.org/wiki/Violeta_(color)#Variedad_de_violetas",
  },
  {
    label: "Vainilla",
    hex: "#f4e6ac",
    link: "https://es.wikipedia.org/wiki/Crema_(color)#Coloraciones_similares",
  },
  {
    label: "Verde",
    hex: "#008000",
    link: "https://es.wikipedia.org/wiki/Verde",
  },
  {
    label: "Verde Abeto",
    hex: "#31372b",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Agua",
    hex: "#87e79e",
    link: "https://es.wikipedia.org/wiki/Verde#Otros_verdes_azulados",
  },
  {
    label: "Verde Amarillento",
    hex: "#57a639",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Amarillo",
    hex: "#c6ce00",
    link: "https://es.wikipedia.org/wiki/Verde_amarillo",
  },
  {
    label: "Verde Azulado",
    hex: "#1f3a3d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Bosque",
    hex: "#228b22",
    link: "https://es.wikipedia.org/wiki/Verde_bosque",
  },
  {
    label: "Verde Botella",
    hex: "#343b29",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Brillante",
    hex: "#00bb2d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Británico de Carreras",
    hex: "#004225",
    link: "https://es.wikipedia.org/wiki/British_racing_green",
  },
  {
    label: "Verde Caña",
    hex: "#6c7156",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Ceniza",
    hex: "#5f7f7a",
    link: "https://es.wikipedia.org/wiki/Verde_ceniza",
  },
  {
    label: "Verde Cian",
    hex: "#00ff99",
    link: "https://es.wikipedia.org/wiki/Verde_cian",
  },
  {
    label: "Verde Claro",
    hex: "#98ff98",
    link: "https://es.wikipedia.org/wiki/Verde#Otros",
  },
  {
    label: "Verde Cromo",
    hex: "#2e3a23",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Esmeralda",
    hex: "#287233",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Ftalo",
    hex: "#0f4f41",
    link: "https://es.wikipedia.org/wiki/Ftalocianina",
  },
  {
    label: "Verde Helecho",
    hex: "#3d642d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Hierba",
    hex: "#35682d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Hoja",
    hex: "#2d572c",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Hooker",
    hex: "#44944a",
    link: "https://es.wikipedia.org/wiki/Verde_Hooker",
  },
  {
    label: "Verde Lanquecino",
    hex: "#bdecb6",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Limón",
    hex: "#c0fe01",
    link: "https://es.wikipedia.org/wiki/Lima_(color)",
  },
  {
    label: "Verde Loro",
    hex: "#52b830",
    link: "https://es.wikipedia.org/wiki/Verde#Otros",
  },
  {
    label: "Verde Luminoso",
    hex: "#84c3be",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Manzana",
    hex: "#8db600",
    link: "https://es.wikipedia.org/wiki/Verde_amarillo#Otros_ejemplos_verde-amarillos",
  },
  {
    label: "Verde Mar",
    hex: "#2e8b57",
    link: "https://es.wikipedia.org/wiki/Verde#Verdes_azulados",
  },
  {
    label: "Verde Mayo",
    hex: "#4c9141",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Menta",
    hex: "#20603d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Militar",
    hex: "#86895d",
    link: "https://es.wikipedia.org/wiki/Verde_oliva",
  },
  {
    label: "Verde Musgo",
    hex: "#2f4538",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Negruzco",
    hex: "#343e40",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Oliva",
    hex: "#424632",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Opalo",
    hex: "#015d52",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Oscuro",
    hex: "#006400",
    link: "https://es.wikipedia.org/wiki/Verde#Otros",
  },
  {
    label: "Verde Parduzco",
    hex: "#39352a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Patina",
    hex: "#316650",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Perlado",
    hex: "#1c542d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Petróleo",
    hex: "#173f35",
    link: "https://es.wikipedia.org/wiki/Verde#Otros_verdes_azulados",
  },
  {
    label: "Verde Pino",
    hex: "#2c5545",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Primavera",
    hex: "#00ff7f",
    link: "https://es.wikipedia.org/wiki/Verde_primavera",
  },
  {
    label: "Verde Puro",
    hex: "#008f39",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Pálido",
    hex: "#89ac76",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Reseda",
    hex: "#587246",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Señales",
    hex: "#317f43",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Tráfico",
    hex: "#308446",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Turquesa",
    hex: "#1e5945",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verde Veronese o Veronés",
    hex: "#009d7a",
    link: "https://es.wikipedia.org/wiki/Verde_Veronese",
  },
  {
    label: "Verde de París o de Schweinfurt",
    hex: "#009966",
    link: "https://es.wikipedia.org/wiki/Verde_de_Par%C3%ADs",
  },
  {
    label: "Verde Ópalo Perlado",
    hex: "#193737",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Verdete, Verdín o Verdigrís",
    hex: "#6ec2af",
    link: "https://es.wikipedia.org/wiki/Cardenillo",
  },
  {
    label: "Vino o Vinotinto",
    hex: "#57080d",
    link: "https://es.wikipedia.org/wiki/Vino_(color)",
  },
  {
    label: "Violeta",
    hex: "#78288c",
    link: "https://es.wikipedia.org/wiki/Violeta_(color)",
  },
  {
    label: "Violeta Pastel",
    hex: "#a18594",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Violeta Perlado",
    hex: "#8673a1",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Violeta Púrpura",
    hex: "#4a192c",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Violeta Señales",
    hex: "#924e7d",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Violeta Érica",
    hex: "#de4c8a",
    link: "https://gist.github.com/lunohodov/1995178",
  },
  {
    label: "Violín",
    hex: "#a10684",
    link: "https://es.wikipedia.org/wiki/Viol%C3%ADn_(color)",
  },
  {
    label: "Viridián",
    hex: "#007f5c",
    link: "https://es.wikipedia.org/wiki/Viridi%C3%A1n",
  },
  {
    label: "Viridián/veridian",
    hex: "#01805d",
    link: "https://es.wikipedia.org/w/index.php?title=Viridi%C3%A1n/Veridian&action=edit&redlink=1",
  },
  {
    label: "Wasabi",
    hex: "#afd77f",
    link: "https://es.wikipedia.org/wiki/Verde_amarillo#Otros_ejemplos_verde-amarillos",
  },
  {
    label: "Wengué",
    hex: "#3d2e2c",
    link: "https://es.wikipedia.org/wiki/Wengu%C3%A9_(color)",
  },
  {
    label: "Wisteria",
    hex: "#c9a0dc",
    link: "https://es.wikipedia.org/wiki/Wisteria_(color)",
  },
  {
    label: "Xanadú",
    hex: "#738678",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Xántico",
    hex: "#f49531",
    link: "https://es.wikipedia.org/wiki/X%C3%A1ntico",
  },
  {
    label: "Yema",
    hex: "#ffe600",
    link: "https://es.wikipedia.org/wiki/Amarillo_naranja#Coloraciones_amarillo-naranjas",
  },
  {
    label: "Yeso",
    hex: "#ebe9e4",
    link: "https://es.wikipedia.org/wiki/Blanco#Variedades_del_blanco",
  },
  {
    label: "Zafiro",
    hex: "#6576b4",
    link: "https://es.wikipedia.org/wiki/Zafiro_(color)",
  },
  {
    label: "Zanahoria",
    hex: "#ed9121",
    link: "https://es.wikipedia.org/wiki/Naranja_(color)#Ejemplos_de_colores_anaranjados",
  },
  {
    label: "Zinc",
    hex: "#91888b",
    link: "https://es.wikipedia.org/wiki/Gris#Muestras_grises",
  },
  {
    label: "Ámbar",
    hex: "#ffbf00",
    link: "https://es.wikipedia.org/wiki/%C3%81mbar_(color)",
  },
  {
    label: "Ébano",
    hex: "#37312b",
    link: "https://es.wikipedia.org/wiki/Negro_(color)#Ejemplos_de_coloraciones_negras",
  },
];
