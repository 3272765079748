import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import styles from "./Sfab.module.css";

const fabTheme = createTheme({
  palette: {
    primary: {
      main: "#e4b261", 
    },
    secondary: {
        main: "#dcc15b", 
      },
  }, 
});

export default function Sfab({ icon, size, onClick, count}) {
  return (
    <div className={styles.fabContainer}>
      <ThemeProvider theme={fabTheme}>
        <Fab onClick={onClick} size={size} color="primary" aria-label="addToCart">
          {icon == "shoppingCart" && (
            <div className={styles.icon}>
              <p>{count || 0}</p>
              <ShoppingCartIcon />
            </div>
          )}
          {icon == "addingToCart" && <AddShoppingCartIcon />}
          {icon == "emptingCart" && <RemoveShoppingCartIcon />}
          {icon == "about" && <HelpOutlineSharpIcon />}
          {icon == "menu" && <MenuSharpIcon />}
        </Fab>
      </ThemeProvider>
    </div>
  );
}
