import React from "react";
import { Form, useSubmit, Link } from "react-router-dom";
import styles from "./Button.module.css";

const Button = (props) => {
  const { formValidity, isSubmitting, id, type, linkTo, ...rest } = props;
  
  return (
    <button
      id={id}
      type={props.type}
      // className={styles[("button", "button__Invalid")]}
      className={`${styles["button"]} ${
        styles[formValidity ? "" : "button__Invalid"]
      }`}
      onClick={rest.onClick}
      disabled={!formValidity || isSubmitting}
    >
      {rest.children}
    </button>
  );
};

export default Button;
