import React, { useEffect, useState, useCallback, Fragment } from "react";
import { useDropzone } from "react-dropzone";
import { useSubmit, json, redirect, useNavigation } from "react-router-dom";
import { BlobServiceClient, BlockBlobClient } from "@azure/storage-blob";
import imageCompression from "browser-image-compression";

import Button from "../shared/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddPic from "@mui/icons-material/AddPhotoAlternate";

import { getAuthToken } from "../../util/AuthToken";

import styles from "./DropBox.module.css";

const containerName = "jstore-products-container";

function DropBox(props) {
  const isSubmitting = useNavigation().state === "loading";

  const pId = props.productInfo._id;
  const urlCount = props.productInfo.pictureUrls.length;

  const [images, setImages] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);
  const [sizeRejection, setSizeRejection] = useState(false);
  const maxSize = 1048576 * 10;
  const [isFileTooLarge, setIsFileTooLarge] = useState();
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      fileRejections.map((rejections) => {
        console.log(rejections.errors[0].code);
        if (rejections.errors[0].code.includes("file-too-large")) {
          setSizeRejection(true);
        }
      });

      setImages(...images, acceptedFiles);
    },
    [images]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
    },
    maxFiles: 5,
    minSize: 0,
    maxSize,
    onDrop,
  });

  const submit = useSubmit();

  const buttonClick = () => {
    setUploading(true);
    let imageCount = urlCount;
    const imageUrls = [];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    images.map((image) => {
      imageCount++;
      const blobName =
        pId + Math.floor(Math.random()*1000000000000)+  "_0" + imageCount.toString() + "." + image.type.split("/")[1];

      async function handleCompression(imageFile) {
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log(
            "is compressedFile an instanceof Blob?",
            compressedFile instanceof Blob
          );

          console.log(
            `File compressedFile size: ${(
              compressedFile.size / 1024
            ).toPrecision(2)} KiB`
          );

          await uploadFileToBlob(compressedFile);
        } catch (error) {
          console.log(error);
        }
      }

      async function uploadFileToBlob(compressedImage) {
        //Create ServiceCliente
        const blobServiceClient = new BlobServiceClient(
          process.env.REACT_APP_BLOB_URL
        );
        // create container client
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        // create blob client
        // const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const response = await blockBlobClient.uploadData(compressedImage, {
          blockSize: 4 * 1024 * 1024,
          concurrency: 2,
        });

        if (response._response.status != 201) {
          console.log("error");
          throw json(
            { message: "Error al cargar las imagenes" },
            { status: 500 }
          );
        } else {
          const productUpdate = {
            productId: pId,
            imageUrl: response._response.request.url.split("?")[0],
          };
          console.log(productUpdate);
          const responseUpdate = await fetch(
            `${process.env.REACT_APP_BACKEND}/products/update-urls`,
            {
              method: "POST",
              body: JSON.stringify(productUpdate),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getAuthToken()}`,
              },
            }
          );

          if (!responseUpdate.ok) {
            setUploading(false);
            throw json(
              { message: "Error al cargar las imagenes" },
              { status: responseUpdate.status }
            );
          } else {
            console.log(responseUpdate);
            setUploading(false);
            submit(null, {
              method: "get",
              action: "/catalogo",
            });
          }
        }
      }

      handleCompression(image);
    });
  };

  return (
    <Fragment>
      <div className={styles.dropbox} {...getRootProps()}>
        <input {...getInputProps()} />

        {!isDragActive && (
          <div className={styles.dropTitle}>
            <p>Haz click aquí o arrastra tus imagenes</p>
            <h1>
              <AddPic />
            </h1>
          </div>
        )}

        {isDragActive && !isDragReject && <p>"Suelta tus imagenes"</p>}
        {isDragReject && (
          <p>Solo se aceptan archivos tipo imagen .jpg y .png</p>
        )}
        {fileRejections.length > 0 && (
          <p style={{ color: "red" }}>
            Esta imagen es muy grande, intenta con otra menor a 10MB
          </p>
        )}
        <div></div>
        {acceptedFiles.length > 0 && <img src={images[0]} alt="" />}
      </div>
      {acceptedFiles.length > 0 && (
        <div className={styles.imgList}>
          <p>Listo para subir:</p>
          <ul>
            {acceptedFiles.length > 0 &&
              acceptedFiles.map((file) => <li key={file.name}>{file.name}</li>)}
          </ul>
        </div>
      )}
      <div className={styles.actions}>
        <Button
          onClick={buttonClick}
          formValidity={acceptedFiles.length > 0 && !isSubmitting && !uploading}
          
        >
          {isSubmitting || uploading ? "Subiendo..." : " Subir Imágenes"}
        </Button>
      </div>
    </Fragment>
  );
}

export default DropBox;
