import React, { Fragment, useEffect, useContext } from "react";
import {
  Outlet,
  useRouteLoaderData,
  useLoaderData,
  useSubmit,
} from "react-router-dom";

import MainNavigation from "../components/shared/MainNavigation";
import Footer from "../components/shared/Footer";
import { getTokenDuration } from "../util/AuthToken";

import CartContextProvider from "../store/ShoppingCart-Context";

// import { getTokenDuration } from "../util/authToken";

const RootPage = () => {
  const token = useLoaderData();
  const submit = useSubmit();

  //Set timer to logout
  useEffect(() => {
    if (!token) {
      return;
    }

    if (token === "EXPIRED") {
      submit(null, { method: "post", action: "/salir" });
      return;
    }

    const tokenDuration = getTokenDuration();

    setTimeout(() => {
      submit(null, { method: "post", action: "/salir" });
    }, tokenDuration);
  }, [token, submit]);

  return (
    <CartContextProvider>
      <section className="whole-page">
        <MainNavigation></MainNavigation>

        <main>
          <Outlet />
        </main>
      </section>
      <Footer></Footer>
    </CartContextProvider>
  );
};
export default RootPage;
