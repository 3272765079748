import Button from "../../components/shared/Button";
import styles from "./CatalogActions.module.css";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

const CatalogActions = () => {
  return (
    <div className={styles["actions-container"]}>
      <Button disabled={false} formValidity={true}>
        <Link to={"/catalogo/nuevo"}>
          <div className={styles.wap}>
            <AddIcon /> Crear
          </div>
        </Link>
      </Button>
    </div>
  );
};

export default CatalogActions;
