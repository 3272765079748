import React, { Fragment, useState, useContext } from "react";
import {
  json,
  useSearchParams,
  useParams,
  redirect,
  Form,
  useNavigation,
} from "react-router-dom";

import styles from "./CatalogPage.module.css";

import ProductList from "../components/products/ProductList";
import { getAuthToken } from "../util/AuthToken";

import Intro from "./Catalog/Intro";

const Catalog = () => {
  const token = getAuthToken();
  const navigation = useNavigation();
  const isProcessing = navigation.state;

  return (
    <>
      <section className={styles.container}>
        <Intro />
        <ProductList></ProductList>
      </section>
    </>
  );
};

export default Catalog;

export async function loader() {
  const response = await fetch(`${process.env.REACT_APP_BACKEND}/products/all`);

  if (!response.ok) {
    throw json({ message: response.statusText }, { status: response.status });
  } else {
    return response;
  }
}
