import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLoaderData, json, Link } from "react-router-dom";
import ArrowB from "@mui/icons-material/ArrowBackIosNew";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";
//Import custom components
import Carousel from "../components/shared/Carousel";
import Button from "../components/shared/Button";
import styles from "./ProductPage.module.css";
import Modal from "../components/shared/Modal";

const ProductPage = () => {
  const product = useLoaderData();
  const images = product.pictureUrls;
  const phone = "https://wa.me/50687246730?text=";

  const [isFocused, setIsFocused] = useState(false);
  const [focusedImage, setIsFocusedImage] = useState("");

  const handleImageClick = (e) => {
    setIsFocused(!isFocused);
    setIsFocusedImage(e.target.src);
  };

  const handleBuyProduct = () => {
    const url =
      phone +
      encodeURIComponent(
        "Hola, quisiera comprar este producto: " +
          product.title +
          " disponible en este enlace: " +
          `https://artezo.shop/catalogo/${product.category}/${product._id}`
      );

    window.location.replace(url);
  };

  const imageAnimations = {
    width: isFocused ? "95vw" : "40px",
    height: isFocused ? "75vh" : "40px",
    display: isFocused ? "block" : "none",
    position: isFocused ? "absolute" : "relative",
    objectFit: isFocused ? "contain" : "contain",
    translate: isFocused ? "-30px -370px" : "100vh",

    opacity: 1,
  };

  return (
    <>
      <div className={styles.container}>
        {" "}
        {/* <div className={styles.controls}>
        <Link to={-1}>
          <div className={styles.back}>
            <ArrowB /> Regresar
          </div>
        </Link>
      </div> */}
        <div className={styles["description-zone"]}>
          <h1>{`${product.title}`} </h1>

          <p>{`${product.category}  en color ${product.color}`}</p>
        </div>
        <div className={styles["image-zone"]}>
          <Carousel images={images} />
        </div>
        <AnimatePresence>
          <motion.div
            className={styles.zoom}
            onClick={handleImageClick}
            initial={{ x: 0, y: 0, opacity: 0 }}
            animate={imageAnimations}
            exit={{ x: 0, opacity: 0 }}
          >
            <img src={focusedImage} alt={product.title} />
            <div>
              <p>
                <ZoomOutIcon />
              </p>
              <p>Toca la imagen regresar </p>
            </div>
          </motion.div>
        </AnimatePresence>
        <div className={styles.details}>
          <p>
            <span className={styles.bold}></span> {product.description}
          </p>
          {/* <p>
          <span className={styles.bold}>Categoría: </span>
          {product.category}
        </p>
        <p>
          <span className={styles.bold}>Color disponible: </span>
          {product.color}
        </p>
        <p>
          <span className={styles.bold}>Disponibles: </span>
          {product.count}
        </p> */}
          <h3>
            {product.price.toLocaleString("en-US", {
              style: "currency",
              currency: "CRC",
            })}
          </h3>
        </div>
        <div className={styles["product-actions"]}>
          <Link to={-1}>
            <Button disabled={false} formValidity={true}>
              <div className={styles.back}>Regresar</div>
            </Button>
          </Link>

          <Button
            disabled={false}
            formValidity={true}
            onClick={handleBuyProduct}
          >
            Comprar
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProductPage;

export async function loader({ request, params }) {
  const { productId } = params;

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND}/products/${productId}`,
    { method: "Get" }
  );
  if (!response.ok) {
    throw json(
      { message: "Error al cargar los detalles del producto" },
      { status: response.status }
    );
  }
  const resData = await response.json();

  return resData.product;
}
