import React, { useEffect, useState } from "react";
import {
  Form,
  Link,
  useSearchParams,
  useParams,
  useActionData,
  useNavigation,
  useLoaderData,
  json,
  useSubmit,
  redirect,
} from "react-router-dom";
import Dropzone from "react-dropzone";

import Card from "../shared/Card";

import Input from "../shared/Input";
import Button from "../shared/Button";
import DropbBox from "../shared/DropBox";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { getAuthToken } from "../../util/AuthToken";
import CustomAutoComplete from "./AutoComplete";
import { ColorList } from "./Colors";
import styles from "./ProductForm.module.css";

const categoryOptions = [
  "Anillos",
  "Aretes",
  "Collares",
  "Juegos Completos",
  "Tobilleras",
  "Pulseras",
  "Hombre",
];

const EditProductForm = (props) => {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const navigation = useNavigation();

  const token = getAuthToken();

  const productDetails = useLoaderData();
 
  const submit = useSubmit();
  const params = useParams();
  const actionData = useActionData();

  const isSubmitting = useNavigation().state === "loading";

  const newExpenseActionUrl =
    "/user/" + props.userId + "/budgets/" + props.budgetId + "/new";

    const colorOptions = ColorList.map((color) => {
      return color.label;
    });

  ////////////////////////////Managing State

  const [imgUrls, setImgUrls] = useState(productDetails.pictureUrls);

  const [ColorInputValue, setColorInputValue] = useState("");
  const [userInput, setUserInput] = useState({
    name: productDetails.title,
    description: productDetails.description,
    price: productDetails.price,
    count: productDetails.count,
    imageUrl: productDetails.pictureUrls,
    category: productDetails.category,
    color: productDetails.color || "Seleccionar",
    coverUrl: productDetails.coverUrl,
    standOut: productDetails.standOut,
  });

  const [files, setFiles] = useState([]);
  const [nameValidity, setNameValidity] = useState(true);
  const [descriptionValidity, setDescriptionValidity] = useState(true);
  const [priceValidity, setPriceValidity] = useState(true);
  const [countValidity, setCountValidity] = useState(true);
  const [categoryValidity, setCategoryValidity] = useState(true);
  const [colorValidity, setColorValidity] = useState(true);
  const [formValidity, setFormValidity] = useState(null);
  const [switchStatus, setSwitchStatus] = useState(false);
  const [productStandOut, setProductStandOut] = useState(
    productDetails.standOut
  );

  useEffect(() => {
    const timerIdentifier = setTimeout(() => {
      setFormValidity(
        userInput.name.trim().length > 3 &&
          userInput.description.trim().length > 3 &&
          userInput.price > 0 &&
          userInput.category.trim().length > 0 &&
          // userInput.color.trim().length > 0 &&
          userInput.count > 0
      );
    }, 500);
    //This is a cleanup function
    return () => {
      clearTimeout(timerIdentifier);
    }; //This is a cleanup function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userInput.name,
    userInput.description,
    userInput.price,
    userInput.category,
    userInput.color,
    userInput.count,
    userInput.imageUrl,
    userInput.standOut,
    userInput.coverUrl,
  ]);

  /////////////////////////Change Handlers

  const nameChangehandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, name: event.target.value };
    });
  };

  const descriptionChangehandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, description: event.target.value };
    });
  };

  const priceChangehandler = (event) => {
    var currency = event.target.value;
    var number = Number(currency.replace(/[^0-9.-]+/g, ""));
    
    setUserInput((prevState) => {
      return { ...prevState, price: event.target.value };
    });
  };

  const countChangehandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, count: event.target.value };
    });
  };

  const categoryChangeHandler = (value) => {
    if (value) {
      setUserInput((prevState) => {
        return { ...prevState, category: value };
      });
    }
  };

  const colorChangeHandler = (value) => {
    if (value) {
      setUserInput((prevState) => {
        return { ...prevState, color: value };
      });
    }
  };

  const urlsChangeHandler = (event) => {
    const deleteUrl = event.target.id;

    setUserInput((prevState) => {
      return {
        ...prevState,
        imageUrl: imgUrls.filter((img, index) => img != deleteUrl),
      };
    });
    setImgUrls(imgUrls.filter((img, index) => img != deleteUrl));
  };

  const setDefaultImageHandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, coverUrl: event.target.id };
    });
  };

  const standOutHandler = () => {
    setProductStandOut(!productDetails.standOut);
    setUserInput((prevState) => {
      return { ...prevState, standOut: productStandOut };
    });
  };

  const checkInputValidity = (event) => {
    const inputName = event.target.name;
    console.log(inputName)
    if (inputName === "name")
      setNameValidity(event.target.value.trim().length > 3);
    if (inputName === "description")
      setDescriptionValidity(event.target.value.trim().length > 3);
    if (inputName === "price")
      setPriceValidity(parseInt(event.target.value) > 0);
    if (inputName === "count") setCountValidity(event.target.value > 0);
    if (inputName === "category")
      setCategoryValidity(event.target.value.trim().length > 0);
    if (inputName === "color")
      setColorValidity(event.target.value.trim().length > 0);
  };

  const handleUpdateSubmit = () => {
    const productId = productDetails._id;
    const productUpdate = { ...userInput, productId };

    async function updateProduct() {
      const responseUpdate = await fetch(
        `${process.env.REACT_APP_BACKEND}/products/update`,
        {
          method: "POST",
          body: JSON.stringify(productUpdate),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );

      if (!responseUpdate.ok) {
        throw json(
          { message: "Error al actualizar el producto" },
          { status: responseUpdate.status }
        );
      } else {
        return redirect(`/catalogo/${productId}` + productId, responseUpdate);
      }
    }

    updateProduct();
  };

  if (!token) {
    return redirect("/");
  }
  return (
    <div className={styles.container}>
      <div className={styles.instructions}>
        <h1>Editar Producto</h1>
        <p>
          Esta es la información actual del producto. Puedes editar o actualizar
          todos los espacios correspondientes.{" "}
        </p>
      </div>
      {!actionData && (
        <Form className={styles.form} onSubmit={handleUpdateSubmit}>
          <div className={styles["form-control"]}>
            <label className={styles["label-title"]}>Nombre del producto</label>
            <Input
              name="name"
              onChange={nameChangehandler}
              inputType="input"
              type="text"
              value={userInput.name}
              formValidity={nameValidity}
              onBlur={checkInputValidity}
            ></Input>
            {nameValidity === false && (
              <p className={styles.warning}>
                El nombre de contener al menos 3 caracteres
              </p>
            )}
          </div>
          <div className={styles["form-control"]}>
            <label className={styles["label-title"]}>Descripcion</label>
            <Input
              name="description"
              inputType="input"
              type="text"
              onChange={descriptionChangehandler}
              value={userInput.description}
              formValidity={descriptionValidity}
              onBlur={checkInputValidity}
            ></Input>
            {!descriptionValidity && (
              <p className={styles.warning}>
                El tipo debe contener al menos 3 caracteres
              </p>
            )}
          </div>
          <div className={styles["form-control"]}>
            <CustomAutoComplete
              id="category"
              name="category"
              formValidity={categoryValidity}
              onBlur={checkInputValidity}
              selectOptions={categoryOptions}
              selectLabel={"Categoría"}
              currentValue={userInput.category}
              changeHandler={categoryChangeHandler}
            />

            {!categoryValidity && (
              <p className={styles.warning}>Debes Seleccionar una categoría</p>
            )}
          </div>
          <div className={styles["form-control"]}>
            <CustomAutoComplete
              id="color"
              name="color"
              formValidity={categoryValidity}
              onBlur={checkInputValidity}
              selectOptions={colorOptions}
              selectLabel={"Color"}
              currentValue={userInput.color}
              changeHandler={colorChangeHandler}
            />

            {!colorValidity && (
              <p className={styles.warning}>Selecciona un color</p>
            )}
          </div>
          <div className={styles["form-control"]}>
            <Input
              name="price"
              inputType="input"
              type="text"
              placeholder="₡ Precio"
              onChange={priceChangehandler}
              value={userInput.price}
              formValidity={priceValidity}
              onBlur={checkInputValidity}
            ></Input>
            {!priceValidity && (
              <p className={styles.warning}>El precio es incorrecto </p>
            )}
          </div>
          <div className={styles["form-control"]}>
            <label className={styles["label-title"]}>Disponibles</label>
            <Input
              name="count"
              inputType="input"
              type="text"
              onChange={countChangehandler}
              value={userInput.count}
              formValidity={countValidity}
              onBlur={checkInputValidity}
            ></Input>
            {!countValidity && (
              <p className={styles.warning}>
                La cantidad disponible debe ser mayor a 0{" "}
              </p>
            )}
          </div>
          <div className={styles["form-control"]}>
            <label className={styles["label-title"]}>Destacar</label>
            <Checkbox
              defaultChecked={productDetails.standOut}
              onClick={standOutHandler}
            />
          </div>

          <div className={styles.instructions}>
            <h1>Imágenes de este producto</h1>
            <p>
              Para borrar una imagen, haz clic en el ícono y luego haz clic en
              actualizar.{" "}
            </p>
            {imgUrls.length == 0 && (
              <div>
                <p>No hay imagenes para mostrar</p>
              </div>
            )}

            <p>En portada</p>
            {productDetails.coverUrl ? (
              <div className={styles["cover-item"]}>
                <img src={productDetails.coverUrl} alt={productDetails.title} />
              </div>
            ) : (
              <p>No has seleccionado imagen de portada</p>
            )}
            {imgUrls.length > 0 && (
              <>
                <p>Adicionales</p>
                <div className={styles["product-images"]}>
                  {imgUrls.map((picture, index) => {
                    return (
                      <div className={styles["image-item"]} key={picture}>
                        <div className={styles["image-actions"]}>
                          <Fab
                            size="small"
                            color="primary"
                            aria-label="delete"
                            id={picture}
                            onClick={urlsChangeHandler}
                          >
                            <DeleteIcon
                              id={picture}
                              onClick={urlsChangeHandler}
                            ></DeleteIcon>
                          </Fab>

                          <Switch
                            {...label}
                            size="small"
                            onClick={setDefaultImageHandler}
                            defaultChecked={productDetails.coverUrl == picture}
                            id={picture}
                          />
                        </div>

                        <img
                          id={index}
                          src={picture}
                          alt={productDetails.title}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div className={styles["form-control_button"]}>
            <Button
              formValidity={!isSubmitting}
              type="submit"
              isSubmitting={isSubmitting}
            >
              {!isSubmitting ? "Actualizar" : "Guardando..."}
            </Button>
          </div>
        </Form>
      )}
      <div className={styles.instructions}>
        <h1>Agregar más imágenes</h1>
      </div>
      <DropbBox productInfo={productDetails} isSubmitting={isSubmitting} />
    </div>
  );
};

export default EditProductForm;

export async function action({ request, params }) {
  const data = await request.formData();
}
