import { useContext } from "react";
import { CartContext } from "../../../store/ShoppingCart-Context";
import Modal from "../Modal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Button from "../Button";
import CartQuantityControl from "./CartQuantyControl";

import styles from "./Cart.module.css";

export default function Cart({ onBackDropClick }) {
  const phone = "https://wa.me/50687246730?text=";
  const { items } = useContext(CartContext);
 
  const totalCartPrice = items.reduce(
    (accumulator, item) => accumulator + item.price * item.quantity,
    0
  );

  const myProducts = [];
  items.forEach((item) => {
    myProducts.push(item.productUrl);
  });
  
  const url =
    phone +
    encodeURIComponent(
      "Hola, quisiera comprar los productos a continuacion: " +
        ` [${myProducts.toString()}]` +
        `Total en carrito: ${totalCartPrice}`
    );
  

  const handleBuyProduct = () => {
    const myProducts = [];
    items.forEach((item) => {
      myProducts.push(item.productUrl);
    });
    
    const url =
      phone +
      encodeURIComponent(
        "Hola, quisiera comprar los productos a continuacion: \n" +
          `[ ${myProducts.toString()} ] \n` +
          `Total en carrito: ${ toColones(totalCartPrice)}`
      );
    
    window.location.replace(url);
  };

  function toColones(amount) {
    return amount.toLocaleString("es-Es", {
      style: "currency",
      currency: "CRC",
    });
  }

  return (
    <Modal onBackDropClick={onBackDropClick}>
      <div>
        <h3>Carrito de compras</h3>
        <div className={styles.itemsContainer}>
          {items.length <= 0 && <p>No hay articulos en el carrito.</p>}
          {items.map((item) => {
            return (
              <div className={styles.cartItem} key={item._id}>
                <div className={styles.cartImg}>
                  <p>{item.title}</p>
                  <img src={item.pictureUrls[0]} alt={item.title} />
                </div>
                <CartQuantityControl quantity={item.quantity} id={item._id} />

                <p className={styles.price}>{`(${toColones(item.price)})`}</p>
              </div>
            );
          })}
        </div>
        <div className={styles.total}>
          <h3>Total: {toColones(totalCartPrice)}</h3>
        </div>
      </div>

      <div className={styles.actions}>
        {" "}
        <Button disabled={false} formValidity={true} onClick={onBackDropClick}>
          Seguir viendo
        </Button>
        {items.length >0 && (        <Button disabled={false} formValidity={true} onClick={handleBuyProduct}>
          <div className={styles.wap}>
            Comprar
          </div>
        </Button>)}

      </div>
    </Modal>
  );
}
