import { styled } from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useContext } from "react";
import { CartContext } from "../../../store/ShoppingCart-Context";

const ControlContainer = styled.div`
  display: flex;

  align-content: center;
  justify-content: center;
  margin-left: 10px;
`;

const QuantityP = styled.p`
  font-size: 1rem;
  margin: auto;
  margin-left: 3px;
  margin-right: 3px;
  padding-bottom: 5px;
`;

export default function CartQuantityControl({ quantity, id }) {
  const { changeItemQuantity } = useContext(CartContext);

  return (
    <ControlContainer>
      <p
        onClick={() => {
          changeItemQuantity(id, -1);
        }}
      >
        {" "}
        <RemoveIcon fontSize="inherit" />
      </p>
      <QuantityP>{quantity}</QuantityP>
      <p
        onClick={() => {
          changeItemQuantity(id, 1);
        }}
      >
        {" "}
        <AddIcon fontSize="inherit" />
      </p>
    </ControlContainer>
  );
}
