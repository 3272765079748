

import styles from "./Intro.module.css";

const Intro = () => {
  return (
    <section className={styles.container}>
      <div className={styles.intro}>
        <h1>Catálogo de accesorios</h1>

        <p>
          Aquí encontrarás todos los accesorios que tenemos en tienda ZO. Todos
          nuestros productos son 100% artesanales hechos con materiales de alta
          calidad.
        </p>
        <hr></hr>
      </div>
    </section>
  );
};

export default Intro;
