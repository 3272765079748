import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CartIcon from "@mui/icons-material/ShoppingCart";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFC5C5",
    },
    secondary: { main: "#C7DCA7" },
  },
});

export default function UsingColorObject() {
  return (
    <ThemeProvider theme={theme}>
      <Fab variant="extended" sx={{ mr: 1 }} color="primary">
        Carrito
        <CartIcon></CartIcon>
      </Fab>
    </ThemeProvider>
  );
}
