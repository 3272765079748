import { useState, useContext } from "react";

import { AnimatePresence, delay, motion } from "framer-motion";

import { CartContext } from "../../store/ShoppingCart-Context";
import About from "./About/About";

import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import Fab from "@mui/material/Fab";

import Cart from "./Cart/Cart";
import Sfab from "./Sfab";

import styles from "./HiddenArea.module.css";

export default function HiddenArea() {
  const { items } = useContext(CartContext);

  const totalItems = items.reduce(
    (accumulator, item) => accumulator + item.quantity,
    0
  );

  const [isHidden, setIsHidden] = useState(true);
  const [cartModalShow, setCartModalShow] = useState(false);
  const [aboutModalShow, setAboutModalShow] = useState(false);

  const handleVisibility = () => {
    setIsHidden(!isHidden);
  };

  const handleCartModal = () => {
    setCartModalShow(!cartModalShow);
  };

  const handleAboutModal = () => {
    setAboutModalShow(!aboutModalShow);
  };

  const visibleProperties = [{ marginLeft: "0" }];

  return (
    <>
      <motion.div
        className={styles.hiddenArea}
        animate={!isHidden ? { backgroundColor: "#e4b2616e" } : null}
      >
        <motion.div
          className={styles.visibleTrigger}
          onClick={handleVisibility}
          animate={!isHidden ? { backgroundColor: "#ffffff00" } : null}
        >
          <motion.div
            animate={!isHidden ? { rotate: 180 } : null}
            //   transition={{ delay: 1 }}
          >
            <ArrowRightSharpIcon />
          </motion.div>
        </motion.div>
        <motion.div
          className={styles.hiddenIcons}
          animate={!isHidden ? visibleProperties : null}
        >
          <Sfab
            size={"medium"}
            icon={"shoppingCart"}
            onClick={handleCartModal}
            count={totalItems}
          ></Sfab>
          <Sfab
            size={"medium"}
            icon={"about"}
            onClick={handleAboutModal}
          ></Sfab>
        </motion.div>
      </motion.div>
      {cartModalShow && <Cart onBackDropClick={handleCartModal}></Cart>}
      {aboutModalShow && <About onBackDropClick={handleAboutModal}></About>}
    </>
  );
}
