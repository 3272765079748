import React, { Fragment } from "react";
import styles from "./HomePage.module.css";
import { useSubmit, useNavigation, useLoaderData } from "react-router-dom";

import Button from "../components/shared/Button";
import Modal from "../components/shared/Modal";

import logo from "../components/zoLogos/zo3.png";

import spin from "../components/spin.png";

const Home = () => {
  const phone = "https://wa.me/50687246730?text=";

  const submit = useSubmit();
  console.log(useNavigation());
  const navigation = useNavigation();

  const handleSubmit = () => {
    submit(null, { method: "get", action: "/catalogo" });
  };

  const handleBuyProduct = () => {
    const url =
      phone + encodeURIComponent("Hola, quisiera solicitar un accesorio... ");
    window.location.replace(url);
    console.log(useNavigation);
  };

  return (
    <Fragment>
      {/* <video
        src="https://player.vimeo.com/external/556159125.sd.mp4?s=957586ce7f6c4528011a7f916d778433779dbed6&profile_id=165&oauth2_token_id=57447761"
        preload
        autoPlay
        loop
        muted
        type="video/mp4"
      ></video> */}

      <section className={styles.container}>
        <div className={styles["logo"]}>
          <div className={styles.zo}>
            
            <img className={styles.image} src={logo}></img>
          </div>

          
          <div className={styles.action}>
            <p>
              Accesorios de bisutería hechos a mano y completamente originales.
            </p>

            <Button disabled={false} formValidity={true} onClick={handleSubmit}>
              Ver Catálogo
            </Button>
            {navigation.state === "loading" && (
              <Modal>
                <div className={styles.loading}>
                  <h3>Cargando lista de accesorios</h3>
                  <img src={spin} alt="cargando" />
                  <h3>Por favor espere...</h3>
                </div>
              </Modal>
            )}
            <Button
              disabled={false}
              formValidity={true}
              onClick={handleBuyProduct}
            >
              Contacto
            </Button>
          </div>
        </div>
      </section>

      {/* <section className={styles.secontainer}>
        <div className={styles.about}>
          <h1>Materiales de alta calidad</h1>
          <p>
            {" "}
            Materiales de calidad, duraderos y siempre con brillo...
          </p>

          <Button disabled={false} formValidity={true} onClick={handleSubmit}>
            Ver mas
          </Button>

   
        </div>
        <div className={styles.about}>
          <h1>Diseños a gusto y medida</h1>
          <p>
            {" "}
            Resalta tu estilo y belleza con diseños a gusto y medida... </p>
          <Button
            disabled={false}
            formValidity={true}
            onClick={handleBuyProduct}
          >
            Solicitar
          </Button>
        </div>
      </section> */}

    </Fragment>
  );
};

export default Home;
