import React, { Fragment } from "react";
import { redirect, json } from "react-router-dom";
import { getAuthToken } from "../util/AuthToken";

import ProductForm from "../components/products/ProductForm";

const FormPage = () => {
  return <ProductForm></ProductForm>;
};

export default FormPage;

export async function action({ request, params }) {
  const redirectUlr = "";
  const data = await request.formData();
   
  const newProduct = {
    name: data.get("name"),
    description: data.get("description"),
    price: parseInt(data.get("price")),
    count: parseInt(data.get("count")),
    imageUrl: data.get("imageUrl"),
    category: data.get("category"),
    color: data.get("color"),
  };
  
  console.log("Sending new product to backend for processing...");
  console.log(newProduct)
  
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND}/products/new`,
    {
      method: "POST",
      body: JSON.stringify(newProduct),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
    }
  );
  if (!response.ok) {
    console.log(response.statusText);
    throw json({ message: response.statusText }, { status: response.status });
  }
  console.log(response.statusText);
  return redirect("/catalogo/nuevo"), response;
}
