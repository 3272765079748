import { redirect } from "react-router-dom";

export function action() {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userName");
  localStorage.removeItem("userId");
  return redirect("/catalogo");
}
