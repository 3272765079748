import React, { Fragment, useState } from "react";
import {
  Form,
  Link,
  useParams,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";
import { motion } from "framer-motion";
import CartIcon from "@mui/icons-material/ShoppingCart";
import LoginIcon from "@mui/icons-material/Login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Fab from "@mui/material/Fab";
import styles from "./MainNavigation.module.css";
import { getAuthToken } from "../../util/AuthToken";
import MyFab from "../shared/test";
import MobileMainNavigation from "./MobileMainNavigation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Sfab from "./Sfab";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e4b261",
    },
    secondary: {
      main: "#dcc15b",
    },
  },
});
const MainNavigation = () => {
  const submitLogout = useSubmit();
  const params = useParams();
  const token = getAuthToken();
  let greeting = "";
  if (token) {
    greeting = `Hola ${localStorage.getItem("userName").split(" ")[0]}, `;
  }

  /// State Management

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  //Handle logout action in navigation menues
  const handleLogout = () => {
    setShowMobileMenu(false);
    submitLogout(null, { method: "post", action: "/salir" });
  };

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const hideMenuHandler = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <h1></h1>
        <p>REALZA TU ESTILO</p>
      </div>

      <nav className={styles.nav}>
        <div className={styles.navItems}>
          <ul className={styles.list}>
            <li>
              <div className={styles.special}>
                <Link to="/catalogo">Catálogo</Link>
                {token && (
                  <motion.div
                    className={styles.expand}
                    onClick={handleExpand}
                    animate={{
                      rotate: isExpanded ? 180 : 0,

                      color: isExpanded ? "#d6be5e" : "#867070",
                    }}
                  >
                    {" "}
                    <ExpandMoreIcon />
                  </motion.div>
                )}
              </div>
              {token && (
                <motion.div
                  className={styles.expandible}
                  animate={{
                    display: isExpanded ? "block" : "none",
                  }}
                >
                  <ul>
                    <li>
                      <Link to="/catalogo/nuevo">Agregar</Link>
                    </li>

                    <li>
                      <Link to="/inicio">Materiales</Link>
                    </li>

                    <li>
                      <Link to="/registros">Inventario</Link>
                    </li>
                  </ul>
                </motion.div>
              )}
            </li>

            {token && (
              <li onClick={handleLogout}>
                <Link to="/catalogo">Salir</Link>
              </li>
            )}
            {!token && (
              <li>
                <Link to="/acceder">
                  <div></div>
                  {/* <LoginIcon />  */}
                  Acceder
                </Link>
              </li>
            )}
          </ul>
        </div>

        <div className={styles.menuButton}>
          <Sfab size={"small"} icon={"menu"} onClick={handleMobileMenu}></Sfab>
        </div>
      </nav>
      {showMobileMenu && (
        <MobileMainNavigation
          onBackDropClick={hideMenuHandler}
          handleExpand={handleExpand}
          handleLogout={handleLogout}
          isExpanded={isExpanded}
        />
      )}
    </header>
  );
};

export default MainNavigation;
