import React, { Fragment, useContext } from "react";
import { Form, useSubmit, Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { CartContext } from "../../store/ShoppingCart-Context";

import DeleteIcon from "@mui/icons-material/DeleteForever";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EditIcon from "@mui/icons-material/ModeEdit";


import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

//check for Auth

import { getAuthToken } from "../../util/AuthToken";

//import custom components
import Card from "../shared/Card";
import Button from "../shared/Button";

//Styles
import styles from "./ProductItem.module.css";

const ProductItem = (props) => {
  const submit = useSubmit();
  const token = getAuthToken();

  const {addItemToCart} = useContext(CartContext)



  const { product } = props;
  product.pictureUrls.map((url, index) => {});
  const phone = "https://wa.me/50687246730?text=";

  const handleBuyProduct = () => {
    const url =
      phone +
      encodeURIComponent(
        "Hola, quisiera comprar este producto: " +
          product.title +
          " disponible en este enlace: " +
          `https://artezo.shop/catalogo/${product.category}/${product._id}`
      );
    console.log(url);
    window.location.replace(url);
  };

  const confirmDeletion = () => {
    const confirmed = window.confirm("Deseas eleminar este producto?");
    if (confirmed) {
      submit(null, { method: "post", action: `${product._id}/eliminar` });
    }
  };

  return (
    <AnimatePresence>
      <Card>
        <div className={styles.image}>
          <Link to={`${product.category.toLowerCase()}/${product._id}`}>
            <img
              src={product.coverUrl ? product.coverUrl : product.pictureUrls[0]}
              alt={product.title}
            ></img>
          </Link>
          {/* <div className={styles.createdBy}>
            <p>{product.createdBy.name}</p>
          </div> */}
        </div>

        <div className={styles.detail}>
          <h1>{product.title}</h1>
          <p>{product.description}</p>
          <h3>
            {product.price.toLocaleString("en-US", {
              style: "currency",
              currency: "CRC",
            })}
          </h3>
        </div>
        <div className={styles.actions}>
          {/* <Button disabled={false} formValidity={true}>
          Ver Detalles
        </Button> */}

          <Button
            disabled={false}
            formValidity={true}
            onClick={handleBuyProduct}
          >
            <div className={styles.wap}>
              <WhatsAppIcon />
            </div>
          </Button>
          <Button
            disabled={false}
            formValidity={true}
            product ={product}
            onClick= {()=>{addItemToCart(product)}}
          >
            <div className={styles.wap}>
              <AddShoppingCartIcon />
            </div>
          </Button>

          <Button
           
            linkTo={`${product.category.toLowerCase()}/${product._id}`}
            disabled={false}
            formValidity={true}
          >
            <Link to={`${product.category.toLowerCase()}/${product._id}`}>
              Detalles
            </Link>
          </Button>
        </div>
        {token && (
          <div className={styles.actions}>
            <Button disabled={false} formValidity={true}>
              <Link to={product._id + "/editar"}>
                <EditIcon />
              </Link>
            </Button>

            <Button
              disabled={false}
              formValidity={true}
              onClick={confirmDeletion}
            >
              <DeleteIcon></DeleteIcon>{" "}
            </Button>
            {/* <p>Portada</p>
            <select>
              {product.pictureUrls.map((url, index) => {
                return <option>{index}</option>;
              })}
            </select> */}
          </div>
        )}

        {/* <h2>
          <FavoriteIcon />
        </h2> */}
      </Card>
    </AnimatePresence>
  );
};

export default ProductItem;
