
import Sfab from './Sfab'
import styles from './Foooter.module.css'
export default function Footer(){
    return(
        <div className={styles.footer}>
            <p className={styles.rights}> © 2024 Artezo.shop</p> 
            <Sfab cartType="shopping" fabSize={"medium"}></Sfab>
        </div>
    )
}