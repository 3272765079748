import React, { useEffect, useState } from "react";
import {
  Form,
  useSearchParams,
  useParams,
  useActionData,
  useNavigation,
  use,
} from "react-router-dom";
import Dropzone from "react-dropzone";

import Card from "../shared/Card";

import Input from "../shared/Input";
import Button from "../shared/Button";
import DropbBox from "../shared/DropBox";
import CustomAutoComplete from "./AutoComplete";

import Fab from "@mui/material/Fab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import styles from "./ProductForm.module.css";
import { ColorList } from "./Colors";
const categoryOptions = [
  "Anillos",
  "Aretes",
  "Collares",
  "Juegos Completos",
  "Tobilleras",
  "Pulseras",
  "Hombre",
];
const ProductForm = (props) => {
  const colorOptions = ColorList.map((color) => {
    return color.label;
  });

  const params = useParams();
  const actionData = useActionData();

  const isSubmitting = useNavigation().state === "submitting";

  const newExpenseActionUrl =
    "/user/" + props.userId + "/budgets/" + props.budgetId + "/new";

  ////////////////////////////Managing State

  const [userInput, setUserInput] = useState({
    name: "",
    description: "",
    price: "",
    count: "",
    imageUrl: [],
    category: "",
    color: "",
  });
  const [files, setFiles] = useState([]);
  const [nameValidity, setNameValidity] = useState(true);
  const [descriptionValidity, setDescriptionValidity] = useState(true);
  const [priceValidity, setPriceValidity] = useState(true);
  const [countValidity, setCountValidity] = useState(true);
  const [categoryValidity, setCategoryValidity] = useState(true);

  const [colorValidity, setColorValidity] = useState(true);
  const [formValidity, setFormValidity] = useState(null);

  files.map((file) => {});
  useEffect(() => {
    const timerIdentifier = setTimeout(() => {
      setFormValidity(
        userInput.name.trim().length > 3 &&
          userInput.description.trim().length > 3 &&
          userInput.price > 0 &&
          userInput.category.trim().length > 0 &&
          userInput.color.trim().length > 0 &&
          userInput.count > 0
      );
    }, 500);
    //This is a cleanup function
    return () => {
      clearTimeout(timerIdentifier);
    }; //This is a cleanup function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userInput.name,
    userInput.description,
    userInput.price,
    userInput.category,
    userInput.color,
    userInput.count,
  ]);

  /////////////////////////Change Handlers

  const dropBoxChangeHandler = (images) => {
    setFiles(images);
  };

  const nameChangehandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, name: event.target.value };
    });
  };

  const descriptionChangehandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, description: event.target.value };
    });
  };

  const priceChangehandler = (event) => {
    var currency = event.target.value;
    var number = Number(currency.replace(/[^0-9.-]+/g, ""));

    setUserInput((prevState) => {
      return { ...prevState, price: event.target.value };
    });
  };

  const countChangehandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, count: event.target.value };
    });
  };

  const categoryChangeHandler = (value) => {
    if (value) {
      setUserInput((prevState) => {
        return { ...prevState, category: value };
      });
    }
  };
  const colorChangeHandler = (value) => {
    if (value) {
      setUserInput((prevState) => {
        return { ...prevState, color: value };
      });
    }
  };

  const checkInputValidity = (event) => {
    const inputName = event.target.name;
    console.log(event.target.name);
    if (inputName === "name")
      setNameValidity(event.target.value.trim().length > 3);
    if (inputName === "description")
      setDescriptionValidity(event.target.value.trim().length > 3);
    if (inputName === "price") setPriceValidity(event.target.value > 0);
    if (inputName === "count") setCountValidity(event.target.value > 0);
    if (inputName === "category")
      setCategoryValidity(
        event.target.value.length > 0 || event.target.value != "Categoría"
      );
    if (inputName === "color")
      setColorValidity(event.target.value.trim().length > 0);
  };

  return (
    <div className={styles.container}>
      <div className={styles.instructions}>
        <h1>Paso 1</h1>
        <p>Agrega la informacion del producto</p>
      </div>
      {!actionData && (
        <Form className={styles.form} method="post" action="/catalogo/nuevo">
          <div className={styles["form-control"]}>
            <label className={styles["label-title"]}>Nombre del producto</label>
            <Input
              name="name"
              onChange={nameChangehandler}
              inputType="input"
              type="text"
              value={userInput.name}
              formValidity={nameValidity}
              onBlur={checkInputValidity}
            ></Input>
            {nameValidity === false && (
              <p className={styles.warning}>
                El nombre de contener al menos 3 caracteres
              </p>
            )}
          </div>
          <div className={styles["form-control"]}>
            <label className={styles["label-title"]}>Descripcion</label>
            <Input
              name="description"
              inputType="input"
              type="text"
              onChange={descriptionChangehandler}
              value={userInput.description}
              formValidity={descriptionValidity}
              onBlur={checkInputValidity}
            ></Input>
            {!descriptionValidity && (
              <p className={styles.warning}>
                El tipo debe contener al menos 3 caracteres
              </p>
            )}
          </div>

          <div className={styles["form-control"]}>
            <CustomAutoComplete
              selectId="category"
              selectName="category"
              formValidity={categoryValidity}
              onBlur={checkInputValidity}
              selectOptions={categoryOptions}
              selectLabel={"Categoría"}
              currentValue={userInput.category}
              changeHandler={categoryChangeHandler}
            />

            {!categoryValidity && (
              <p className={styles.warning}>Debes Seleccionar una categoría</p>
            )}
            {/* <input
              type="hidden"
              id="category"
              value={userInput.category}
              name="category"
            ></input> */}
          </div>
          <div className={styles["form-control"]}>
            <CustomAutoComplete
              value={userInput.color}
              name="color"
              selectId="color"
              selectName="color"
              formValidity={categoryValidity}
              onBlur={checkInputValidity}
              selectOptions={colorOptions}
              selectLabel={"Color"}
              currentValue={userInput.color}
              changeHandler={colorChangeHandler}
            />
                        {/* <input
              type="hidden"
              id="color"
              value={userInput.color}
              name="color"
            ></input> */}

            {!colorValidity && (
              <p className={styles.warning}>Selecciona un color</p>
            )}
          </div>

          <div className={styles["form-control"]}>
            <Input
              name="price"
              inputType="input"
              type="text"
              placeholder="₡ Precio"
              onChange={priceChangehandler}
              value={userInput.price}
              formValidity={priceValidity}
              onBlur={checkInputValidity}
            ></Input>
            {!priceValidity && (
              <p className={styles.warning}>El precio es incorrecto </p>
            )}
          </div>
          <div className={styles["form-control"]}>
            <label className={styles["label-title"]}>Disponibles</label>
            <Input
              name="count"
              inputType="input"
              type="text"
              onChange={countChangehandler}
              value={userInput.count}
              formValidity={countValidity}
              onBlur={checkInputValidity}
            ></Input>
            {!countValidity && (
              <p className={styles.warning}>
                La cantidad disponible debe ser mayor a 0{" "}
              </p>
            )}
          </div>

          <div className={styles["form-control"]}>
            <Button
              formValidity={formValidity}
              type="submit"
              isSubmitting={isSubmitting}
            >
              {!isSubmitting ? "Crear Producto" : "Creando..."}
            </Button>
          </div>
        </Form>
      )}
      <div className={styles.instructions}>
        <h1>Paso 2</h1>
        <p>Sube las imägenes del producto</p>
      </div>
      {actionData && <DropbBox productInfo={actionData.product} />}
    </div>
  );
};

export default ProductForm;

export async function action({ request, params }) {
  const data = await request.formData();
}
