import React, { Fragment, useState } from "react";
import { Link, useLoaderData, useParams } from "react-router-dom";

import { getAuthToken } from "../../util/AuthToken";

import ProductItem from "./ProductItem";
import CatalogControls from "../shared/CatalogControls";
import styles from "./ProductList.module.css";

import CatalogActions from "../../pages/Catalog/CatalogActions";
import HiddenArea from "../shared/HiddenArea";


const ProductList = () => {
  const token = getAuthToken();
  const { products } = useLoaderData();
  const { category } = useParams();

  products.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));

  const categoryGroups = [];
  const allCategories = products.map((product) => product.category);
  const productCategories = [...new Set(allCategories)].sort();
  const prodImgs = [
    {
      type: "Anillos",
      url: "https://jstore0stracc.blob.core.windows.net/jstore-products-container/undefined/6577775081fdc3f7d9413a74.jpg",
    },

    {
      type: "Aretes",
      url: "https://jstore0stracc.blob.core.windows.net/jstore-products-container/undefined/65777a1581fdc3f7d9413a9e.jpg",
    },
    {
      type: "Juegos Completos",
      url: "https://jstore0stracc.blob.core.windows.net/jstore-products-container/undefined/65722f346f63b15a885c877f.jpg",
    },
    {
      type: "Collares",
      url: "https://jstore0stracc.blob.core.windows.net/jstore-products-container/undefined/6577796d81fdc3f7d9413a92.jpg",
    },
    {
      type: "Pulseras",
      url: "https://jstore0stracc.blob.core.windows.net/jstore-products-container/undefined/657777ae81fdc3f7d9413a7a.jpg",
    },
  ];

  const [filterCategory, setFilterCategory] = useState(category || "TODOS");

  const searchBackground = (stringType) => {
    return prodImgs
      .filter((image) => image.type === stringType)
      .map((image) => image.url);
  };

  const catalogCategory = (event) => {
    setFilterCategory(event.target.id);
  };

  productCategories.forEach((category) => {
    const product = products.filter((product) => product.category === category);

    categoryGroups.push({ key: category, category, product });
  });

  return (
    <Fragment>
      <div className={styles.Categories}>
        <p>Categorías</p>

        {products.length === 0 && <p>No hay ningun articulo en el catalogo</p>}

        <ul className={styles.catalogMenu}>
          {" "}
          {categoryGroups.map((group) => (
            <li className={styles.catalogMenuCategory} key={group.key}>
              <Link
                onClick={catalogCategory}
                id={group.category}
                to={`${group.category.toLowerCase()}`}
              >
                {group.category.split(" ")[0].toUpperCase()}
              </Link>
            </li>
          ))}
          <li className={styles.catalogMenuCategory}>
            <Link onClick={catalogCategory} id="TODOS" to="todos">
              TODOS
            </Link>
          </li>
        </ul>
        {token && <CatalogActions />}
      </div>
      <HiddenArea></HiddenArea>
      

      <div className={styles.category}>
        {!filterCategory && <h1>Todos</h1>}
        <h1>{filterCategory.toUpperCase()}</h1>
        {filterCategory.toUpperCase() == "TODOS" && (
          <p>
            {"Disponibles: "} {products.length}
          </p>
        )}

        {filterCategory.toUpperCase() != "TODOS" && (
          <p>
            {"Disponibles: "}
            {
              products.filter(
                (product) =>
                  product.category.toUpperCase() ===
                  filterCategory.toUpperCase()
              ).length
            }
          </p>
        )}
      </div>

      <section id={filterCategory} className={styles.catalog}>
        {filterCategory.toUpperCase() == "TODOS" &&
          products.map((product) => (
            <ProductItem key={product._id} product={product}></ProductItem>
          ))}
        {products
          .filter(
            (product) =>
              product.category.toUpperCase() === filterCategory.toUpperCase()
          )
          .map((product) => (
            <ProductItem key={product._id} product={product}></ProductItem>
          ))}
      </section>
    </Fragment>
  );
};

export default ProductList;
