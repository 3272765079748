import React, { Fragment } from "react";
import { useRouteError } from "react-router-dom";
import { useSubmit, useNavigation, useLoaderData } from "react-router-dom";

import MainNavigation from "../components/shared/MainNavigation";
import Button from "../components/shared/Button";

import styles from "./ErrorPage.module.css";

function ErrorPage() {
  const error = useRouteError();
  console.log(error);
  const submit = useSubmit();

  const handleSubmit = () => {
    submit(null, { method: "get", action: "/catalogo" });
  };

  return (
    <Fragment>
      <MainNavigation></MainNavigation>

      <main>
        <div className={styles.error}>
          <h3>Algo salio mal...</h3>
          <p>
            {" "}
            Intentalo de nuevo o contacta con nosotros si el problema persiste.
            Gracias.{" "}
          </p>

          <Button disabled={false} formValidity={true} onClick={handleSubmit}>
            Ir al Catálogo
          </Button>
        </div>
      </main>
    </Fragment>
  );
}

export default ErrorPage;
