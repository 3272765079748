import React from "react";
import { Link, Form, useSubmit } from "react-router-dom";
import { getAuthToken } from "../../util/AuthToken";
import Modal from "../shared/Modal";
import { motion } from "framer-motion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "./Button";

import styles from "./MainNavigation.module.css";

const MobileMainNavigation = (props) => {
  const submitLogout = useSubmit();
  const token = getAuthToken();

  const handleLogout = () => {
    props.onBackDropClick();
    submitLogout(null, { method: "post", action: "/salir" });
  };

  return (
    <Modal onBackDropClick={props.onBackDropClick}>
      <div className={styles.mobile}>
        <h2>Menu</h2>

        <ul className={styles.list__mobile}>


          <li>
            <div className={styles.special}>
              <Link to="/catalogo" onClick={props.onBackDropClick}>
                Catálogo
              </Link>
              {token && (
                <motion.div
                  className={styles.expand}
                  onClick={props.handleExpand}
                  animate={{
                    rotate: props.isExpanded ? 180 : 0,

                    color: props.isExpanded ? "#870050" : "#867070",
                  }}
                >
                  {" "}
                  <ExpandMoreIcon />
                </motion.div>
              )}
            </div>
            {token && (
              <motion.div
                className={styles.expandible}
                animate={{
                  display: props.isExpanded ? "block" : "none",
                }}
              >
                <ul className={styles.submenu}>
                  <li>
                    <Link to="/catalogo/nuevo" onClick={props.onBackDropClick}>
                      Agregar
                    </Link>
                  </li>

                  <li>
                    <Link to="/inicio" onClick={props.onBackDropClick}>
                      Materiales
                    </Link>
                  </li>

                  <li>
                    <Link to="/registros" onClick={props.onBackDropClick}>
                      Inventario
                    </Link>
                  </li>
                </ul>
              </motion.div>
            )}
          </li>

          <li>
            <div className={styles.special}>
              <Link to="/inicio" onClick={props.onBackDropClick}>
                Sobre ZO
              </Link>
            </div>
          </li>

          {/* <li>
            <div className={styles.special}>
              <Link to="/contacto" onClick={props.onBackDropClick}>
                Contacto
              </Link>
            </div>
          </li> */}
          {!token && (
            <div className={styles.special}>
              <li>
                <Link to="/acceder" onClick={props.onBackDropClick}>
                  Acceder
                </Link>
              </li>
            </div>
          )}

          {token && (
            <div className={styles.special}>
              <li onClick={props.handleLogout}>
                <Link to="/catalogo">Salir </Link>
              </li>
            </div>
          )}
        </ul>
      </div>
    </Modal>
  );
};

export default MobileMainNavigation;
