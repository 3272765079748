import React, { Fragment, useEffect, useState } from "react";

import {
  useSearchParams,
  Link,
  Form,
  useActionData,
  useNavigation,
} from "react-router-dom";

import Input from "../shared/Input";
import Button from "../shared/Button";

import styles from "./AuthForm.module.css";

const AuthForm = (props) => {
  //////////////////////////Consts
  const data = useActionData();
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();
  const isLogin = searchParams.get("mode") === "login";
  const isSubmitting = navigation.state === "submitting";

  /////////////////////////Managing State
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });

  const [emailValidity, setEmailValidity] = useState(true);
  const [passwordValidity, setPasswordValidity] = useState(true);
  const [formValidity, setFormValidity] = useState(null);

  useEffect(() => {
    const timerIdentifier = setTimeout(() => {
      setFormValidity(
        userInput.email.includes("@") && userInput.password.trim().length > 6
      );
    }, 500);
    //This is a cleanup function
    return () => {
      clearTimeout(timerIdentifier);
    };
  }, [userInput.email, userInput.password]);

  /////////////////////////Change Handlers

  const emailChangeHandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, email: event.target.value };
    });
    setEmailValidity(event.target.value.includes("@"));
  };

  const passwordChangeHandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, password: event.target.value };
    });
    setPasswordValidity(event.target.value.trim().length > 6);
  };

  return (
    <Fragment>
      <Form method="post" className={styles["form-container"]}>
        <h2 className={styles.title}>Iniciar Sesión</h2>

        <div className={styles["form-control"]}>
          <Input
            name="email"
            inputType="input"
            type="text"
            placeholder="Correo electrónico "
            value={userInput.email}
            onChange={emailChangeHandler}
            formValidity={emailValidity}
          />
          {emailValidity === false && (
            <p className={styles.warning}>
              Ingresa un correo valido nombre@dominio.com
            </p>
          )}
        </div>
        <div className={styles["form-control"]}>
          <Input
            name="password"
            inputType="input"
            type="password"
            placeholder="Contraseña"
            value={userInput.password}
            onChange={passwordChangeHandler}
            formValidity={passwordValidity}
          ></Input>
          {passwordValidity === false && (
            <p className={styles.warning}>
              La contraseña debe contener al menos 6 caracteres
            </p>
          )}
        </div>

        <div className={styles["form-control"]}>
          {data && data.errors && (
            <ul>
              {Object.values(data.errors).map((err) => (
                <li key={err.msg}>
                  {" "}
                  <p className={styles.warning}>{err.msg}</p>
                </li>
              ))}
            </ul>
          )}
          {data && data.message && (
            <p className={styles.warning}>{data.message}</p>
          )}
          <Button 
            
            type="submit"
            formValidity={formValidity}
            disabled={isSubmitting || !formValidity}
          >
            {isSubmitting ? "Enviando..." : "Iniciar"}
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};
export default AuthForm;
