import React from "react";
import { redirect, json} from "react-router-dom";
import { getAuthToken } from "../util/AuthToken";

const DeletePage = () => {
  return <h1> El producto fue borrado... </h1>;
};

export default DeletePage;

export async function action({params}) {
  const productId = params.productId;
  console.log( productId)
  const responseDelete = await fetch(
    `${process.env.REACT_APP_BACKEND}/products/${productId}/delete`,
    {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
    }
  );

  if(responseDelete){
    console.log(responseDelete)
  }

  if (!responseDelete.ok) {
    throw json(
      { message: "Error al borrar el producto" },
      { status: responseDelete.status }
    );
  } else {
    console.log(responseDelete);
    return redirect("/catalogo");
  }
}
