import React from "react";
import {
  createBrowserRouter as Router,
  RouterProvider, Navigate
} from "react-router-dom";

//Import custom elements
import RootPage from "./pages/RootPage";
import HomePage from "./pages/HomePage";
import Catalog, { loader as catalogLoader } from "./pages/CatalogPage";
import ProductForm, { action as newProductAction } from "./pages/FormPage";
import EditProductForm from "./components/products/EditProductForm";

import Auth, { action as authAction } from "./pages/AuthPage";
import ErrorPage from "./pages/ErrorPage";
import { action as logoutAction } from "./components/authentication/Signout";
import { checkAuthLoader, tokenLoader } from "./util/AuthToken";
import ProductPage, { loader as productLoader } from "./pages/ProductPage";
import DeletePage, { action as deleteAction } from "./pages/DeletePage";

//router is created with an array of objects that are routes and its attributes
const router = Router([
  {
    path: "/",
    element: <RootPage />,
    errorElement: <ErrorPage />,
    id: "root",
    loader: tokenLoader,
    children: [
      {
        path: "",
        index: true,
        element: <Navigate replace to="/catalogo" />,
        
      },
      {
        path: "catalogo",
        element: <Catalog />,
        loader: catalogLoader,

        children: [
          {
            path: ":productId/edit",
            element: <ProductForm />,
            loader: checkAuthLoader,
          },
          { path: ":productId/delete", loader: checkAuthLoader },
          { path: ":category", element: <Catalog />, loader: catalogLoader },
        ],
      },
      // { path: "contacto", element: <Form /> },
      { path: "acceder", element: <Auth />, action: authAction },
      //action: authAction
      {
        path: "catalogo/nuevo",
        element: <ProductForm />,
        action: newProductAction,
        loader: checkAuthLoader,
      },
      {
        path: "catalogo/:category/:productId",
        element: <ProductPage />,
        loader: productLoader,
      },
      {
        path: "catalogo/:productId/editar",
        element: <EditProductForm />,
        loader: productLoader,
      },
      {
        path: "catalogo/:productId/eliminar",
        element: <DeletePage />,
        action: deleteAction,
      },
      {
        path: "salir",
        action: logoutAction,
      },
    ],
  },
  {
    path: "/inicio",
    index: true,
    element: <HomePage />,
    errorElement: <ErrorPage />,
    id: "home",
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
