import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function CustomAutoComplete({
  selectId,
  selectName,
  selectOptions,
  selectLabel,
  currentValue,
  changeHandler,
  checkInputValidity,
}) {
  const [inputValue, setInputValue] = useState(currentValue);
  
  return (
    <div name={selectName}>
      <Autocomplete
        onBlur={checkInputValidity}
        value={currentValue}
        onChange={(event, newValue) => {
          console.log(event);
          changeHandler(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={selectOptions}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            id={selectId}
            name={selectName}
            value={currentValue}
            {...params}
            label={selectLabel}
          />
        )}
      />
    </div>
  );
}
