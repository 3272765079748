import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import styles from "./Carousel.module.css";
import Modal from "./Modal";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState("left");
  const [isFocused, setIsFocused] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const [hideZoom, setHideZoom] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);

  const handleNext = () => {
    setDirection("right");
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === images.length ? 0 : prevIndex + 1
    );
  };
  const handlePrevious = () => {
    setDirection("left");
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
    );
  };
  const handleDotClick = (index) => {
    setDirection(index > currentIndex ? "right" : "left");
    setCurrentIndex(index);
  };

  const handleZoomclick = () => {
    setShowZoom(true);
    setImageIndex(currentIndex);
  };

  const handleBackdropClick = () => {
    setShowZoom(false);
  };

  const handleZoomImageClick = (e) => {
    setImageIndex(e.target.id);
  };

  ////Efects
  const slideVariants = {
    hiddenRight: {
      x: "20%",
      y: "0%",
      opacity: 0,
    },
    hiddenLeft: {
      x: "-20%",
      y: "0%",
      opacity: 0,
    },
    visible: {
      x: "0",
      y: "0",
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0,
      },
    },
  };

  return (
    <>
      <div className={styles.zoomIcon}>
        <ZoomInIcon />
      </div>
      <div className={styles["carousel"]}>
        <AnimatePresence>
          <motion.img
            onClick={handleZoomclick}
            key={currentIndex}
            src={images[currentIndex]}
            variants={slideVariants}
            initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
            animate="visible"
            exit="exit"
          />
        </AnimatePresence>
        <div className={styles["slide_direction"]}>
          <div className={styles.left} onClick={handlePrevious}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 96 960 960"
              width="20"
            >
              <path d="M400 976 0 576l400-400 56 57-343 343 343 343-56 57Z" />
            </svg>
          </div>
          <div className={styles.right} onClick={handleNext}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 96 960 960"
              width="20"
            >
              <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" />
            </svg>
          </div>
        </div>
        <div className={styles["carousel-indicator"]}>
          {images.map((_, index) => (
            <div
              key={index}
              className={`${styles["dot"]} ${
                styles[currentIndex === index ? "active" : ""]
              }`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
      </div>
      {showZoom && (
        <Modal onBackDropClick={handleBackdropClick}>
          <p>Selecciona una imagen:</p>
          <div className={styles.igmbox}>
            {images.map((picture, index) => {
              return (
                <motion.img
                  id={index}
                  src={picture}
                  alt={`zoom image`}
                  onClick={handleZoomImageClick}
                  animate={
                    index == imageIndex
                      ? { boxShadow: "#fd8f0059 0px 3px 5px" }
                      : null
                  }
                />
              );
            })}
          </div>
          <p>
            Desplaza la imagen para ver más detalles o toca fuera de este cuadro
            para regresar.
          </p>
          <div className={styles.zoom}>
            <img src={images[imageIndex]} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Carousel;
