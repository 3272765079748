import React from "react";
import { motion } from "framer-motion";

import styles from "./Card.module.css";

const Card = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, stiffness: 300 }}
      transition={{
        stiffnes: 100,
        bounce: 100,
      }}
      exit={{ opacity: 0 }}
      className={`${styles.card} ${styles[props.color]}`}
    >
      {props.children}
    </motion.div>
  );
};

export default Card;
