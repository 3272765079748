import Modal from "../Modal";
import styles from "./About.module.css";

export default function About({ onBackDropClick }) {
  return (
    <Modal onBackDropClick={onBackDropClick}>
      <h2>Sobre Tienda Zo</h2>

      <p>
        Nuestros productos son 100% hechos a mano. Los materiales utilizados son
        de calidad inmejorable para garantizar la durabilidad de cada accesorio
        para que puedas realzar tu belleza natural con un hermoso estilo por
        mucho tiempo.
      </p>

      <h2>¿Como comprar en Tienda Zo?</h2>
      <div className={styles.step}>
        <h2>1</h2>
        <p>Agrega los productos al carrito de compras.</p>
      </div>
      <div className={styles.step}>
        <h2>2</h2>
        <p>Desde el carrito de compras, selecciona comprar.</p>
      </div>
      <div className={styles.step}>
        <h2>3</h2>
        <p>
          Se abrirá un enlace a nuestro Whatsapp donde podras coordinar todos
          los detalles de la compra.
        </p>
      </div>
      <div className={styles.important}>
        {" "}
        <h2>Importante</h2>
        <p>
          {" "}
          Nunca pediremos información de tus tarjetas o cuentas bancarias. Los
          pagos se haran contra entrega, en efectivo o SINPE móvil.{" "}
        </p>
      </div>
    </Modal>
  );
}
