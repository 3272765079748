import React from "react";
import { json, redirect } from "react-router-dom";

//import custom components

import AuthForm from "../components/authentication/AuthForm";
import styles from "./AuthPage.module.css";

const Auth = () => {
  return (
    <main className={styles.authMain}>
      <AuthForm></AuthForm>
    </main>
  );
};

export default Auth;

export async function action({ request }) {
  
  const data = await request.formData();

  const authData = {
    email: data.get("email"),
    password: data.get("password"),
  };


  

  const response = await fetch(`${process.env.REACT_APP_BACKEND}/auth/login`, {
    method: "POST",
    body: JSON.stringify(authData),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.status === 401 || response.status === 422) {
    return response;
  }
  if (!response.ok) {
    console.log(response);
    throw json(
      { message: "Error en el servidor, no se pudo autenticar usuario" },
      { status: 500 }
    );
  }

  const responseData = await response.json();
  const token = responseData.token;

  localStorage.setItem("userId", responseData.userId);
  localStorage.setItem("token", token);
  localStorage.setItem("userName", responseData.name);
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);
  localStorage.setItem("expirationDate", expirationDate);
  console.log(response);
  return redirect("/catalogo");
}
